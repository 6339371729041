import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../logics/api_config";
import {IRegistryDetailed} from "../../../utils/interfaces/Registry/IRegistryDetailed";

async function doCreate(payload: any): Promise<IRegistryDetailed> {
    try {
        const response = await API.postEncoded(`/backoffice/registry`, payload);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiCreateRegistry = (): UseMutationResult<IRegistryDetailed, CustomAxiosError, {payload: any }> => {
    const queryClient = useQueryClient();
    return useMutation<IRegistryDetailed, CustomAxiosError, { payload: any }>(
        ({payload}) => doCreate( payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getRegistries"]);
                queryClient.invalidateQueries(["getRegistryDetailed"])
            },
        }
    );
};

export default useApiCreateRegistry;