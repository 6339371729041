import {Box, Collapse, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CustomAxiosError} from "../../../logics/api_config";
import {IMachineDetailed} from "../../../utils/interfaces/Machine/IMachineDetailed";
import useApiGetMachineDetailed from "../api-hooks/useApiGetMachineDetailed";
import MachineEditingForm from "./MachineEditorComponents/MachineEditingForm";
import ModalCloseButton from "../../../ui/ModalCloseButton";
import MachineManager from "./MachineEditorComponents/MachineManager";

interface Props {
    id: number | undefined,
    setIsModalClosed: () => void
}

const MachineEditorModal = ({id, setIsModalClosed}: Props) => {
    const [machineDetailed, setMachineDetailed] = useState<IMachineDetailed>()
    const [postId, setPostId] = useState<number>();

    const handleIdChange = (id: number) => {
        setPostId(id);
    };

    const {data: fetchedMachine, error: fetchedMachineError} = useApiGetMachineDetailed(postId ? postId : id)
    if (fetchedMachineError) {
        const axiosError = fetchedMachineError as CustomAxiosError;
        console.log(axiosError);
    }

    useEffect(() => {
        if (fetchedMachine) {
            setMachineDetailed(fetchedMachine)
        }
    }, [fetchedMachine]);

    return (
        <>
            <ModalCloseButton setIsModalClosed={setIsModalClosed}/>
            <Box
                sx={{
                    backgroundColor: "white",
                    boxShadow: 2,
                    border: '3px solid',
                    borderColor: "primary.dark",
                    borderRadius: "4px",
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}
            >
                <Stack gap={2}>
                    <MachineEditingForm
                        machine={(machineDetailed)
                            ? {
                                id: machineDetailed.id,
                                name: machineDetailed.name,
                                image_url: machineDetailed.image_url
                            }
                            : undefined
                        }
                        onPostIdChange={handleIdChange}
                    />
                    <Collapse in={!!machineDetailed}>
                        {machineDetailed &&
                            <MachineManager machine={{id: machineDetailed.id}}/>
                        }
                    </Collapse>
                </Stack>
            </Box>
        </>
    )
}

export default MachineEditorModal;