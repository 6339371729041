import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doDelete(machineId: number, machineContentId: number): Promise<void> {
    try {
        await API.DELETE(`/backoffice/machine/${machineId}/machine_content/${machineContentId}`);
    } catch (error) {
        throw error;
    }
}

const useApiDeleteMachineContent = (): UseMutationResult<void, CustomAxiosError, { machineId: number; machineContentId: number }> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { machineId: number; machineContentId: number }>(
        ({machineId, machineContentId}) => doDelete(machineId, machineContentId),
        {
            onSuccess: (data, variables) => {
                const { machineId } = variables;
                if (machineId) {
                    queryClient.invalidateQueries(["getMachineContents", machineId]);
                    queryClient.invalidateQueries(["getMachineContentDetailed"]);
                }
            }
        }
    );
};

export default useApiDeleteMachineContent;