import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IPlantDetailed} from "../../../../../utils/interfaces/Registry/RegistryPlant/IPlantDetailed";

async function doCreate(registryId: number, payload: any): Promise<IPlantDetailed> {
    try {
        const result = await API.postEncoded(`/backoffice/registry/${registryId}/plant`, payload);
        return result.data.data
    } catch (error) {
        throw error;
    }
}

const useApiCreateRegistryPlant = (): UseMutationResult<IPlantDetailed, CustomAxiosError, { registryId: number; payload: any }> => {
    const queryClient = useQueryClient();
    return useMutation<IPlantDetailed, CustomAxiosError, { registryId: number; payload: any }>(
        ({ registryId, payload }) => doCreate(registryId, payload),
        {
            onSuccess: (data, variables) => {
                const { registryId } = variables;
                if (registryId) {
                    queryClient.invalidateQueries(["getRegistryDetailed", registryId]);
                    queryClient.invalidateQueries(["getRegistryPlantDetailed"]);
                }
            }
        }
    );
};

export default useApiCreateRegistryPlant;