import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../logics/api_config";
import {IMachineDetailed} from "../../../utils/interfaces/Machine/IMachineDetailed";

async function doCreate(payload: any): Promise<IMachineDetailed> {
    try {
        const response = await API.postEncoded(`/backoffice/machine`, payload);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiCreateMachine = (): UseMutationResult<IMachineDetailed, CustomAxiosError, {payload: any }> => {
    const queryClient = useQueryClient();
    return useMutation<IMachineDetailed, CustomAxiosError, { payload: any }>(
        ({payload}) => doCreate( payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getMachines"]);
                queryClient.invalidateQueries(["getMachineDetailed"])
            },
        }
    );
};

export default useApiCreateMachine;