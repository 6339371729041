import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doCreate(registryId: number, registryPlantId: number, payload: any): Promise<void> {
    try {
        await API.postEncoded(`/backoffice/registry/${registryId}/plant/${registryPlantId}/registry_plant_machine`, payload);
    } catch (error) {
        throw error;
    }
}

const useApiCreateRegistryPlantMachine = (): UseMutationResult<void, CustomAxiosError, {
    registryId: number;
    registryPlantId: number;
    payload: any
}> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { registryId: number; registryPlantId: number; payload: any }>(
        ({registryId, registryPlantId, payload}) => doCreate(registryId, registryPlantId, payload),
        {
            onSuccess: (data, variables) => {
                const {registryPlantId} = variables;
                if (registryPlantId) {
                    queryClient.invalidateQueries(["getRegistryPlantMachines", registryPlantId]);
                }
            }
        }
    );
};

export default useApiCreateRegistryPlantMachine;