import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IMachineContentDetailed} from "../../../../../utils/interfaces/Machine/MachineContent/IMachineContentDetailed";

async function doCreate(machineId: number, machineContentId:number, payload: {machine_content_url: FormData}): Promise<IMachineContentDetailed> {
    try {
        const response = await API.postEncoded(`/backoffice/machine/${machineId}/machine_content/${machineContentId}/machine_content_row`, payload);
        return response.data.data
    } catch (error) {
        throw error;
    }
}

const useApiCreateMachineContentRow = (): UseMutationResult<IMachineContentDetailed, CustomAxiosError, {
    machineId: number;
    machineContentId:number;
    payload: any
}> => {
    const queryClient = useQueryClient();
    return useMutation<IMachineContentDetailed, CustomAxiosError, { machineId: number; machineContentId: number, payload: any }>(
        ({machineId, machineContentId, payload}) => doCreate(machineId, machineContentId, payload),
        {
            onSuccess: (data, variables) => {
                const {machineContentId} = variables;
                if (machineContentId) {
                    queryClient.invalidateQueries(["getMachineContentDetailed", machineContentId]);
                    queryClient.invalidateQueries(["getMachineContentRows"]);
                }
            }
        }
    );
};

export default useApiCreateMachineContentRow;