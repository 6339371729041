import {Box, Button, LinearProgress, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import useApiUpdateRegistry from "../../api-hooks/useApiUpdateRegistry";
import {CustomAxiosError} from "../../../../logics/api_config";
import useApiCreateRegistry from "../../api-hooks/useApiCreateRegistry";
import {registryFormCharLimits} from "../../../../utils/content/content-formLimits";

interface Props {
    registry?: {
        id?: number,
        business_name?: string,
        address?: string,
        city?: string,
        cap?: string,
        nation?: string,
    }
    onPostIdChange: (id: number) => void;
}

const RegistryEditingForm = ({registry, onPostIdChange}: Props) => {
    const [businessName, setBusinessName] = useState<string | undefined>(registry?.business_name);
    const [address, setAddress] = useState<string | undefined>(registry?.address);
    const [city, setCity] = useState<string | undefined>(registry?.city);
    const [cap, setCap] = useState<string | undefined>(registry?.cap);
    const [nation, setNation] = useState<string | undefined>(registry?.nation);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const createMutation = useApiCreateRegistry()
    const updateMutation = useApiUpdateRegistry();

    const handleChangeBusinessName = (event: ChangeEvent<HTMLInputElement>) => {
        setBusinessName(event.target.value);
    }

    const handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    }

    const handleChangeCity = (event: ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    }

    const handleChangeCap = (event: ChangeEvent<HTMLInputElement>) => {
        setCap(event.target.value);
    }

    const handleChangeNation = (event: ChangeEvent<HTMLInputElement>) => {
        setNation(event.target.value);
    }

    const handleCreateRegistry = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        const payload = {
            business_name: businessName || "",
            address: address || "",
            city: city || "",
            cap: cap || "",
            nation: nation || "",
        };
        try {
            const result = await createMutation.mutateAsync({payload: payload})
            onPostIdChange(result.id)
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    const handleConfirmEdit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        const payload = {
            business_name: businessName || "",
            address: address || "",
            city: city || "",
            cap: cap || "",
            nation: nation || "",
        };
        try {
            if (registry?.id) {
                await updateMutation.mutateAsync({registryId: registry.id, payload: payload});
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        setBusinessName(registry?.business_name);
        setAddress(registry?.address);
        setCity(registry?.city);
        setCap(registry?.cap)
        setNation(registry?.nation)
    }, [registry]);

    return (
        <div>
            <Typography>
                Azienda
            </Typography>
            <Box
                component={"form"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "0.5rem"
                }}
                onSubmit={(event) => {
                    registry ? handleConfirmEdit(event) : handleCreateRegistry(event)
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "2rem"
                    }}
                >
                    <TextField
                        required={true}
                        label={"Nome azienda"}
                        name={"Nome azienda"}
                        value={businessName || ""}
                        onChange={handleChangeBusinessName}
                        disabled={isSaving}
                        inputProps={{maxLength: registryFormCharLimits.business_name}}
                    />
                    <TextField
                        label={"Indirizzo"}
                        name={"Indirizzo"}
                        value={address || ""}
                        onChange={handleChangeAddress}
                        disabled={isSaving}
                        inputProps={{maxLength: registryFormCharLimits.address}}
                    />
                    <TextField
                        label={"Città"}
                        name={"Città"}
                        value={city || ""}
                        onChange={handleChangeCity}
                        disabled={isSaving}
                        inputProps={{maxLength: registryFormCharLimits.city}}

                    />
                    <TextField
                        sx={{
                            width:"100px"
                        }}
                        label={"Cap"}
                        name={"Cap"}
                        value={cap || ""}
                        onChange={handleChangeCap}
                        disabled={isSaving}
                        inputProps={{maxLength: registryFormCharLimits.cap}}

                    />
                    <TextField
                        label={"Nazione"}
                        name={"Nazione"}
                        value={nation || ""}
                        onChange={handleChangeNation}
                        disabled={isSaving}
                        inputProps={{maxLength: registryFormCharLimits.nation}}
                    />
                </Box>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        disabled={isSaving || (
                            registry?.business_name === businessName &&
                            registry?.city === city &&
                            registry?.address === address &&
                            registry?.cap === cap &&
                            registry?.nation === nation
                        )}
                    >
                        {registry
                            ? "Salva"
                            : "Crea azienda"
                        }
                    </Button>
                </Box>
                {isSaving &&
                    <LinearProgress/>
                }
            </Box>
        </div>
    );
}

export default RegistryEditingForm;