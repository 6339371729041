import {ElementType, ReactNode, useState} from "react";
import {Button, ButtonProps, SxProps, Theme} from "@mui/material";

interface Props{
    ids: Record<string,number>, //Takes any object with any number of name-value parameters
    label?: string,
    children?: ReactNode,
    style?: SxProps<Theme>,
    variant?: ButtonProps['variant'],
    Component: ElementType<{
        ids: Record<string,number>,
        open: boolean,
        componentClose: () => void,
    }>
}

const ActionOpenModal = ({ids, label, children, style, variant, Component}:Props) => {
    const [open, setOpen] = useState<boolean>(false)

    const handleComponentOpen = () =>{
        setOpen(true)
    }

    const handleComponentClose = () =>{
        setOpen(false)
    }

    return(
        <>
            <Button
                sx={style}
                variant={variant}
                onClick = {handleComponentOpen}
            >
                {label}
                {children}
            </Button>
            {open &&
                <>
                    <Component ids={ids} open={open} componentClose={handleComponentClose}/>
                </>
            }
        </>
    )
}

export default ActionOpenModal;