import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doUpdate(registryId: number, registryPlantId:number, payload: any): Promise<void> {
    try {
        await API.putEncoded(`/backoffice/registry/${registryId}/plant/${registryPlantId}`, payload);
    } catch (error) {
        throw error;
    }
}

const useApiUpdateRegistryPlant = (): UseMutationResult<void, CustomAxiosError, { registryId: number; registryPlantId:number, payload: any }> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { registryId: number; registryPlantId:number, payload: any }>(
        ({registryId, registryPlantId, payload}) => doUpdate(registryId, registryPlantId, payload),
        {
            onSuccess: (data, variables) => {
                const { registryId } = variables;
                if (registryId) {
                    queryClient.invalidateQueries(["getRegistryDetailed", registryId]);
                }
            }
        }
    );
};

export default useApiUpdateRegistryPlant;