import {Box, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import ModalCloseButton from "../../../../../../ui/ModalCloseButton";
import {IRegistryUser} from "../../../../../../utils/interfaces/Registry/RegistryUser/IRegistryUser";
import UserManagerTableEditingForm from "./UserManagerTableEditingForm";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiGetRegistryUserDetailed from "../../../api-hooks/RegistryUser/useApiGetRegistryUserDetailed";

interface Props {
    registry: {
        id: number,
    }
    id?: number | undefined,
    setIsModalClosed: () => void
}

const UserManagerTableEditorModal = ({registry, id, setIsModalClosed}: Props) => {
    const [registryUser, setRegistryUser] = useState<Omit<IRegistryUser, "registry">>()
    const [postId, setPostId] = useState<number>();

    const handleIdChange = (id: number) => {
        setPostId(id);
    };

    const {data: fetchedRegistryUser, error: fetchedRegistryUserError} = useApiGetRegistryUserDetailed(
        registry.id,
        postId ? postId : id
    )
    if (fetchedRegistryUserError) {
        const axiosError = fetchedRegistryUserError as CustomAxiosError;
        console.log(axiosError);
    }

    useEffect(() => {
        if (fetchedRegistryUser) {
            setRegistryUser(fetchedRegistryUser as Omit<IRegistryUser, "registry">)
        }
    }, [fetchedRegistryUser]);


    return (
        <>
            <ModalCloseButton setIsModalClosed={setIsModalClosed}/>
            <Box
                sx={{
                    backgroundColor: "white",
                    boxShadow: 2,
                    border: '3px solid',
                    borderColor: "primary.dark",
                    borderRadius: "4px",
                    minWidth:"40rem",
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}
            >
                <Stack direction={"column"} gap={2}>
                    <UserManagerTableEditingForm
                        registry={registry}
                        registryUser={registryUser? registryUser : undefined}
                        onPostIdChange={handleIdChange}
                    />
                </Stack>
            </Box>
        </>
    )
}

export default UserManagerTableEditorModal;