import {Grow, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {ReactElement} from "react";
import {styled} from "@mui/material/styles";

interface Props extends TooltipProps {
    children: ReactElement;
}

//Style for actual tooltip component, background, border etc
const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: "black",
        maxWidth: "none",
        border: "3px solid",
        borderColor: theme.palette.primary.dark,
        borderRadius: "4px",
        boxShadow: theme.shadows,
    },
}));

const WindowTooltip = ({children, placement = "bottom", ...restProps}: Props) => {

    return (
        <HtmlTooltip
            {...restProps}
            PopperProps={{
                disablePortal: true,
            }}
            TransitionComponent={Grow}
            placement={placement}
            disableTouchListener
            enterDelay={200}
            enterNextDelay={200}
        >
            {children}
        </HtmlTooltip>
    );
};

export default WindowTooltip;
