import {Stack, Typography} from "@mui/material";
import PlantPairerUserFields from "./PlantPairerUserFields";
import PlantPairerMachineFields from "./PlantPairerMachineFields";

interface Props {
    registry: {
        id: number,
    }
    plant: {
        id: number
    }
}

const PlantPairerTab = ({registry, plant}: Props) => {

    return (
        <Stack direction={"row"} gap={2} justifyContent={"space-between"} sx={{width:"100%", height:"100%"}}>
            <Stack sx={{width:"100%"}} gap={1}>
                <Typography>
                    Aggiungi macchinari
                </Typography>
                <PlantPairerMachineFields registry={registry} plant={plant}/>
            </Stack>
            <Stack sx={{borderRight:"1px solid #DFDFDFFF"}} gap={1}>
                <Typography>
                    Utenti impianto
                </Typography>
                <PlantPairerUserFields registry={registry} plant={plant}/>
            </Stack>
        </Stack>
    )
}

export default PlantPairerTab;