import {Box, Collapse, Stack} from "@mui/material";
import React from "react";
import useApiGetMachineContents from "../api-hooks/MachineContent/useApiGetMachineContents";
import {CustomAxiosError} from "../../../../logics/api_config";
import ContentManagerTable from "./MachineManagerComponents/ContentManagerTable";

interface Props {
    machine: {
        id: number,
    }
}

const MachineManager = ({machine}: Props) => {
    const {data: fetchedMachineContents, error: fetchedMachineContentsError} = useApiGetMachineContents(machine.id)
    if (fetchedMachineContentsError) {
        const axiosError = fetchedMachineContentsError as CustomAxiosError;
        console.log(axiosError);
    }

    return (
        <div>
            <Stack
                sx={{p: "8px"}}
                direction="row"
                justifyContent="center" gap={1}
            >
                <Collapse in={!!fetchedMachineContents}>
                    <Box
                        sx={{
                            display: "flex",
                            maxWidth: "61rem",
                            maxHeight: "25rem",
                            flexWrap: "wrap",
                            gap: 2,
                            overflow: "auto",
                        }}
                    >
                        {fetchedMachineContents &&
                            <ContentManagerTable
                                key={machine.id}
                                machine={{id: machine.id}}
                                machineContent={fetchedMachineContents}
                            />
                        }
                    </Box>
                </Collapse>
            </Stack>
        </div>
    )
}

export default MachineManager