import {Box, Collapse, Stack} from "@mui/material";
import PlantManagerTableEditingForm from "./PlantManagerTableEditingForm";
import {useEffect, useState} from "react";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {IPlantDetailed} from "../../../../../../utils/interfaces/Registry/RegistryPlant/IPlantDetailed";
import useApiGetRegistryPlantDetailed from "../../../api-hooks/RegistryPlant/useApiGetRegistryPlantDetailed";
import PlantManagerTablePairer from "./PlantManagerTablePairer";
import ModalCloseButton from "../../../../../../ui/ModalCloseButton";

interface Props{
    registry:{
        id:number,
        address:string,
        city:string,
        cap:string
        nation: string,
    }
    plant?: {
        id?: number,
    }
    setIsModalClosed: () => void
}

const PlantManagerTableEditorModal = ({registry, plant, setIsModalClosed}:Props) => {
    const [registryPlantDetailed, setRegistryPlantDetailed] = useState<IPlantDetailed>()
    const [postId, setpostId] = useState<number>();

    const handleIdChange = (id: number) => {
        setpostId(id);
    };

    const {data:fetchedRegistryPlant, error:fetchedRegistryPlantError} = useApiGetRegistryPlantDetailed(
        registry.id,
        postId? postId:plant?.id
    )
    if (fetchedRegistryPlantError) {
        const axiosError = fetchedRegistryPlantError as CustomAxiosError;
        console.log(axiosError);
    }

    useEffect(() => {
        if(fetchedRegistryPlant){
            setRegistryPlantDetailed(fetchedRegistryPlant)
        }
    }, [fetchedRegistryPlant]);


    return(
        <>
            <ModalCloseButton setIsModalClosed={setIsModalClosed}/>
            <Box
                sx={{
                    backgroundColor:"white",
                    boxShadow: 2,
                    border: '3px solid',
                    borderColor: "primary.dark",
                    borderRadius: "4px",
                    width:"85rem",
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}
            >
                <Stack direction={"column"} gap={2}>
                    <PlantManagerTableEditingForm
                        registry={{
                            id: registry.id,
                            address:registry.address,
                            city: registry.city,
                            cap: registry.cap,
                            nation: registry.nation,
                        }}
                        plant={registryPlantDetailed}
                        onPostIdChange={handleIdChange}
                    />
                    <Collapse in={!!fetchedRegistryPlant}>
                        {fetchedRegistryPlant &&
                            <PlantManagerTablePairer
                                registry={{id:registry.id}}
                                plant={{id:fetchedRegistryPlant?.id}}
                            />
                        }
                    </Collapse>
                </Stack>
            </Box>
        </>
    )
}

export default PlantManagerTableEditorModal;