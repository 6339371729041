import {Stack} from "@mui/material";
import {IPlant} from "../../../../utils/interfaces/Registry/RegistryPlant/IPlant";
import PlantsManagerTable from "./RegistryManagerComponents/PlantsManagerTable";
import UsersManagerTable from "./RegistryManagerComponents/UsersManagerTable";
import React from "react";
import {IRegistryUser} from "../../../../utils/interfaces/Registry/RegistryUser/IRegistryUser";

interface Props{
    registry:{
        id:number,
        address:string,
        city: string,
        cap:string
        nation: string,
        registry_users: IRegistryUser[],
        plants: IPlant[],
    }
}

const RegistryManager = ({registry}:Props) => {

    return(
        <div>
            <Stack
                sx={{ p:"8px"}}
                direction="row"
                justifyContent="center" gap={1}
            >
                <UsersManagerTable
                    registry={{
                        id: registry.id,
                        registry_users: registry.registry_users
                    }}
                />
                <PlantsManagerTable
                    registry={{
                        id:registry.id,
                        address: registry.address,
                        city: registry.city,
                        cap: registry.cap,
                        nation: registry.nation,
                        plants: registry.plants,
                    }}
                />

            </Stack>
        </div>
    )
}

export default RegistryManager