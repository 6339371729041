import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import React, {useMemo} from "react";
import {IRegistryUser} from "../../../../../utils/interfaces/Registry/RegistryUser/IRegistryUser";
import {Button, MenuItem, Stack, Typography} from "@mui/material";
import ButtonOpenModal from "../../../../../ui/OpenModal/ButtonOpenModal";
import UserManagerModalConfirmDelete from "./UsersManagerTableComponents/UserManagerModalConfirmDelete";
import {FaTrash} from "react-icons/fa";
import {RiEditFill} from "react-icons/ri";
import {MRT_Localization_IT} from "material-react-table/locales/it";
import UserManagerTableEditorModal from "./UsersManagerTableComponents/UserManagerTableEditorModal";

interface Props {
    registry: {
        id: number,
        registry_users: IRegistryUser[],
    },
}

const UsersManagerTable = ({registry}: Props) => {
    const registryUsers: IRegistryUser[] = registry.registry_users;

    const registryUsersColumns = useMemo<MRT_ColumnDef<IRegistryUser>[]>(
        () => [
            {
                accessorKey: "user.email",
                id: "email",
                header: "Email utente",
                muiEditTextFieldProps: {
                    type: "email"
                }
            },
            {
                accessorKey: "user.password",
                id: "password",
                header: "Password utente",
                enableEditing: false,
            },
        ],
        [],
    );

    const tableConfig = useMaterialReactTable({
        columns: registryUsersColumns,
        data: registryUsers,
        initialState: {density: 'compact', columnVisibility: {password: false}},
        positionCreatingRow: 'bottom',
        positionActionsColumn: 'last',
        enableEditing: true,
        enableTableHead: false,
        enableTopToolbar: false,
        enablePagination: false,
        muiTableContainerProps: {
            sx: {
                height: "17rem",
                maxHeight: "17rem",
                width: "29rem",
            },
        },
        muiTablePaperProps: {
            sx: {
                border: "1px solid #dddddd",
                borderRadius: "0px",
            },
            elevation: 0,
        },
        muiTableBodyCellProps: {
            sx: {
                maxWidth: "4rem",
                overflow: "auto",
                borderBottom: `1px solid #dddddd`,
                borderRight: `1px solid #dddddd`,
                py: 0.2,
            },
        },
        muiCreateRowModalProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps: {
                sx: {
                    boxShadow: "none",
                    backgroundColor: "transparent"
                },
            },
        },
        muiEditRowDialogProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps: {
                sx: {
                    boxShadow: "none",
                    backgroundColor: "transparent"
                },
            },
        },
        renderBottomToolbar: ({table}) => (
            <div>
                <Button
                    variant="contained"
                    fullWidth={true}
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    Nuovo utente
                </Button>
            </div>
        ),
        //Row editing and creation modal
        renderEditRowDialogContent: ({row, table}) => (
            <UserManagerTableEditorModal
                registry={{
                    id: registry.id,
                }}
                id={row.original.id}
                setIsModalClosed={() => {
                    table.setCreatingRow(null)
                    table.setEditingRow(null)
                }}
            />
        ),
        // //When there is no content
        renderEmptyRowsFallback: () => (
            <Typography
                sx={{
                    padding: 3,
                    color: "#99A1AA",
                    display: "flex",
                    justifyContent: "center",
                    fontStyle: "italic"
                }}
            >
                Questa azienda non ha ancora utenti. Creane uno adesso!
            </Typography>
        ),
        //Delete button in action menu
        renderRowActionMenuItems: ({row}) => [
            <ButtonOpenModal
                key={0}
                ids={{userId: row.original.user.id}}
                style={{color: "#c4402f", textTransform: "none", fontWeight: "bold"}}
                Component={UserManagerModalConfirmDelete}
            >
                <MenuItem sx={{gap: "10px"}}>
                    <FaTrash/>
                    <Typography sx={{fontSize: "0.9rem"}}>Elimina utente</Typography>
                </MenuItem>
            </ButtonOpenModal>
        ],
        icons: {EditIcon: () => <RiEditFill/>},
        localization: MRT_Localization_IT
    })

    return (
        <Stack>
            <Typography>
                Utenti azienda
            </Typography>
            <MaterialReactTable table={tableConfig}/>
        </Stack>
    )
}
export default UsersManagerTable