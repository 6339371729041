import {useQuery, UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IRegistryPlantMachine} from "../../../../../utils/interfaces/Registry/RegistryPlant/IRegistryPlantMachine";

async function doGet(registryId: number, registryPlantId: number, registryPlantMachineId: number): Promise<IRegistryPlantMachine> {
    try {
        const response = await API.get(`/backoffice/registry/${registryId}/plant/${registryPlantId}/registry_plant_machine/${registryPlantMachineId}`);
        return response.data.data[0];
    } catch (error) {
        throw error;
    }
}

const useApiGetRegistryPlantMachineDetailed = (registryId: number, registryPlantId: number, registryPlantMachineId: number): UseQueryResult<IRegistryPlantMachine, CustomAxiosError> => {
    return useQuery<IRegistryPlantMachine, CustomAxiosError>(
        ["getRegistryPlantMachineDetailed", registryPlantId],
        () => doGet(registryId, registryPlantId, registryPlantMachineId),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export default useApiGetRegistryPlantMachineDetailed;
