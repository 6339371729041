import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {ICategory} from "../../../../../utils/interfaces/ICategory";

async function doGet(): Promise<ICategory[] | undefined> {
    try {
        const response = await API.get(`/backoffice/category`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetCategories = (): UseQueryResult<ICategory[] | undefined, CustomAxiosError> => {
    return useQuery<ICategory[] | undefined, CustomAxiosError>(
        ["getCategories" ],
        () => doGet(),
        {
            refetchOnWindowFocus:false,
        }
    );
};

export default useApiGetCategories;
