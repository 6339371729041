import {Button, Dialog, DialogActions, DialogContentText, DialogTitle, ThemeProvider} from "@mui/material";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {THEMES} from "../../../../../../utils/themes/global-theme";
import useApiDeleteMachineContent from "../../../api-hooks/MachineContent/useApiDeleteMachineContent";

interface Props{
    ids: Record<string,number>,
    open: boolean,
    componentClose: () => void
}

const ContentManagerTableModalConfirmDelete = ({ids, open = false, componentClose }:Props) => {
    const confirm_delete_message = "Confermi di voler eliminare questo argomento?";
    const confirm_delete_message_secondary = "Tutti i contenuti verranno cancellati";

    const {mutateAsync: deleteMachineContent} = useApiDeleteMachineContent();

    const handleConfirmDelete = async () => {
        try {
            await deleteMachineContent({machineId:ids.machineId, machineContentId: ids.machineContentId});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.message) {
                console.log(axiosError.response.data.message);
            }
        }
        componentClose();
    };

    return (
        <ThemeProvider theme={THEMES.themeGlobal}>
            <Dialog
                open={open}
                onClose={componentClose}
            >
                <DialogTitle >
                    {confirm_delete_message}
                </DialogTitle>
                <DialogContentText sx={{ml:4}}>
                    {confirm_delete_message_secondary}
                </DialogContentText>
                <DialogActions sx={{my:1}}>
                    <Button
                        onClick={componentClose}
                        variant={"contained"}
                    >
                        Annulla
                    </Button>
                    <Button
                        variant={"contained"}
                        onClick={handleConfirmDelete}
                    >
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default ContentManagerTableModalConfirmDelete;