import {Button, Dialog, DialogActions, DialogTitle, ThemeProvider} from "@mui/material";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {THEMES} from "../../../../../../utils/themes/global-theme";
import useApiDeleteUser from "../../../api-hooks/User/useApiDeleteUser";

interface Props{
    ids: Record<string,number>,
    open: boolean,
    componentClose: () => void
}

const UserManagerModalConfirmDelete = ({ids, open = false, componentClose }:Props) => {
    const confirm_delete_message = "Confermi di voler eliminare questo utente?";

    const deleteMutation = useApiDeleteUser();

    const handleConfirmDelete = async () => {
        try {
            await deleteMutation.mutateAsync({userId:ids.userId});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.message) {
                console.log(axiosError.response.data.message);
            }
        }
        componentClose();
    };

    return (
        <ThemeProvider theme={THEMES.themeGlobal}>
            <Dialog
                open={open}
                onClose={componentClose}
            >
                <DialogTitle>
                    {confirm_delete_message}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={componentClose}
                        variant={"contained"}
                    >
                        Annulla
                    </Button>
                    <Button
                        variant={"contained"}
                        onClick={handleConfirmDelete}
                    >
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default UserManagerModalConfirmDelete;