import React, {useMemo} from "react";
import {
    createRow,
    MaterialReactTable,
    MRT_ColumnDef,
    useMaterialReactTable
} from "material-react-table";
import {IPlant} from "../../../../../utils/interfaces/Registry/RegistryPlant/IPlant";
import {FaTrash} from "react-icons/fa";
import {Button, MenuItem, Stack, Typography} from "@mui/material";
import {MRT_Localization_IT} from "material-react-table/locales/it";
import {RiEditFill} from "react-icons/ri";
import ButtonOpenModal from "../../../../../ui/OpenModal/ButtonOpenModal";
import PlantManagerConfirmDelete from "./PlantsManagerTableComponents/PlantManagerTableModalConfirmDelete";
import PlantManagerTableEditorModal from "./PlantsManagerTableComponents/PlantManagerTableEditorModal";

interface Props {
    registry: {
        id: number,
        address: string,
        city: string,
        cap: string
        nation: string,
        plants: IPlant[]
    },
}

const PlantsManagerTable = ({registry}: Props) => {

    const registryPlantColumns = useMemo<MRT_ColumnDef<IPlant>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Nome impianto",
            },
            {
                accessorKey: "address",
                header: "Indirizzo",
            },
            // {
            //     accessorKey:"city",
            //     header:"Città",
            // },
            // {
            //     accessorKey:"cap",
            //     header:"Cap",
            // },
        ],
        [],
    );

    const tableConfig = useMaterialReactTable({
        columns: registryPlantColumns,
        data: registry.plants,
        initialState: {density: 'compact'},
        positionCreatingRow: 'bottom',
        positionActionsColumn: 'last',
        enableEditing: true,
        enableTableHead: false,
        enableTopToolbar: false,
        enablePagination: false,
        muiTableContainerProps: {
            sx: {
                height: "17rem",
                maxHeight: "17rem",
                width: "29rem",
            },
        },
        muiTablePaperProps: {
            sx: {
                border: "1px solid #dddddd",
                borderRadius: "0px",
            },
            elevation: 0,
        },
        muiTableBodyCellProps: {
            sx: {
                width: "4rem",
                maxWidth: "4rem",
                overflow: "auto",
                borderBottom: `1px solid #dddddd`,
                borderRight: `1px solid #dddddd`,
                py: 0.2,
            },
        },
        muiCreateRowModalProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps: {
                sx: {
                    boxShadow: "none",
                    backgroundColor: "transparent"
                },
            },
        },
        muiEditRowDialogProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps: {
                sx: {
                    boxShadow: "none",
                    backgroundColor: "transparent"
                },
            },
        },
        renderBottomToolbar: ({table}) => (
            <div>
                <Button
                    variant="contained"
                    fullWidth={true}
                    onClick={() => {
                        table.setCreatingRow(createRow(table, {
                            id: 0,
                            name: "",
                            address: registry.address,
                            city: registry.city,
                            cap: registry.cap,
                            nickname: "",
                        }));
                    }}
                >
                    Nuovo impianto
                </Button>
            </div>
        ),
        //Row creation modal
        renderCreateRowDialogContent: ({table}) => (
            <PlantManagerTableEditorModal
                registry={{
                    id: registry.id,
                    address: registry.address,
                    city: registry.city,
                    cap: registry.cap,
                    nation: registry.nation,
                }}
                setIsModalClosed={() => {
                    table.setEditingRow(null);
                    table.setCreatingRow(null)
                }}
            />
        ),
        //Row editing modal
        renderEditRowDialogContent: ({row, table}) => (
            <PlantManagerTableEditorModal
                registry={{
                    id: registry.id,
                    address: registry.address,
                    city: registry.city,
                    cap: registry.cap,
                    nation: registry.nation,
                }}
                plant={{id: row.original.id}}
                setIsModalClosed={() => {
                    table.setEditingRow(null);
                    table.setCreatingRow(null)
                }}
            />
        ),
        //When there is no content
        renderEmptyRowsFallback: () => (
            <Typography
                sx={{
                    padding: 3,
                    color: "#99A1AA",
                    display: "flex",
                    justifyContent: "center",
                    fontStyle: "italic"
                }}
            >
                Questa azienda non ha ancora impianti. Creane uno adesso!
            </Typography>
        ),
        //Delete button in action menu
        renderRowActionMenuItems: ({row}) => [
            <ButtonOpenModal
                key={0}
                ids={{registryId: registry.id, registryPlantId: row.original.id}}
                style={{color: "#c4402f", textTransform: "none", fontWeight: "bold"}}
                Component={PlantManagerConfirmDelete}
            >
                <MenuItem sx={{gap: "10px"}}>
                    <FaTrash/>
                    <Typography sx={{fontSize: "0.9rem"}}>Elimina impianto</Typography>
                </MenuItem>
            </ButtonOpenModal>
        ],
        icons: {EditIcon: () => <RiEditFill/>},
        localization: MRT_Localization_IT
    })

    return (
        <Stack>
            <Typography>
                Impianti azienda
            </Typography>
            <MaterialReactTable table={tableConfig}/>
        </Stack>
    )
}

export default PlantsManagerTable