import {ThemeOptions} from '@mui/material/styles';
import {createTheme} from "@mui/material";

export const themeOptionsGlobal: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#ff771a',
            dark: "#44291c"
        },
        secondary: {
            main: '#66afbf',
        },
        warning: {
            main: '#ed6c02',
        },
        success: {
            main: '#0d2939',
        },
        background: {
            default: 'rgb(247,250,250)',
        },
    },

    components: {
        MuiCssBaseline:{
            styleOverrides:{
                html: {
                    "-webkit-font-smoothing": "antialiased",
                    "-moz-osx-font-smoothing": "grayscale",
                    height: "100%",
                    width: "100%",
                },
            },
        },

        MuiButtonBase:{
            defaultProps:{
                disableRipple: true,
            },
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    borderRadius:"2px",
                    color:"white",
                },
            },
        },

        MuiIconButton:{
            styleOverrides:{
                root:{
                    paddingTop: 0,
                    paddingBottom: 0,
                    transition: "transform",
                    ":hover":{
                        backgroundColor: "transparent",
                        transform: "scale(1.12)"
                    },
                },
            },
        },

        MuiTextField:{
            defaultProps:{
                variant:"standard",
            },
        },

        MuiToolbar:{
            defaultProps:{
                variant:"dense",
            },

            styleOverrides:{
                root:{
                    position:"sticky",
                    zIndex: 1100,
                    width: "100%",
                }
            },
        },

        MuiTableCell:{
            styleOverrides:{
                head:({theme}) => ({
                    height: "4rem",
                    fontSize: "18px",
                    fontWeight: "800",
                    backgroundColor: theme.palette.primary.light,
                    zIndex:1000,
                }),

                body: {
                    fontSize: "16px",
                    borderRight: "1px solid white",
                    borderBottom: "2px solid white",
                },
            },
        },

        MuiModal:{
            styleOverrides:{
                backdrop:{
                    backgroundColor:"rgba(34,34,34,0.27)"
                },
            },
        },


        MuiListItem:{
            defaultProps:{
                dense:true,
                disableGutters: true
            },
        },


        MuiListItemButton: {
            styleOverrides: {
                root: ({theme}) => ({
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                    paddingInline: "1rem",
                    "&:hover": {
                        backgroundColor:  theme.palette.primary.dark,
                    },
                })
            },
        },

        MuiListItemIcon:{
            styleOverrides:{
                root:{
                    paddingLeft:"0.5rem",
                },
            },
        },

        MuiListItemText:{
            styleOverrides:{
                primary:{
                    fontSize:"large",
                },
            },
        },

        MuiDrawer: {
            styleOverrides: {
                root: {
                    flexShrink: 0,
                },
                paper: ({theme }) => ({
                    borderRight: "transparent",
                    overflow: "hidden",
                    backgroundColor: theme.palette.primary.main,
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.standard,
                    }),

                }),
            }
        }
    },
};

const themeGlobal = createTheme(themeOptionsGlobal);

export const THEMES = {
    themeGlobal: themeGlobal,
}