import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doDelete(userId: number): Promise<void> {
    try {
        await API.DELETE(`/backoffice/user/${userId}`);
    } catch (error) {
        throw error;
    }
}

const useApiDeleteUser = (): UseMutationResult<void, CustomAxiosError, { userId: number }> => {
    const queryClient = useQueryClient();

    return useMutation<void, CustomAxiosError, { userId: number}>(
        ({userId}) => doDelete(userId),
        {
            onSuccess: () => {
                    queryClient.invalidateQueries(["getRegistryDetailed"]);
                    queryClient.invalidateQueries(["getRegistryPlantUsers"]);
            }
        }
    );
};

export default useApiDeleteUser;