import {useQuery, UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IMachineContentRow} from "../../../../../utils/interfaces/Machine/MachineContentRow/IMachineContentRow";

async function doGet(machineId: number, machineContentId: number | undefined): Promise<IMachineContentRow[] | undefined> {
    try {
        const response = await API.get(`/backoffice/machine/${machineId}/machine_content/${machineContentId}/machine_content_row`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetMachineContentRows = (machineId: number, machineContentId: number | undefined): UseQueryResult<IMachineContentRow[] | undefined, CustomAxiosError> => {
    return useQuery<IMachineContentRow[] | undefined, CustomAxiosError>(
        ["getMachineContentRows", machineContentId],
        () => doGet(machineId, machineContentId),
        {
            refetchOnWindowFocus:false,

        }
    );
};

export default useApiGetMachineContentRows;
