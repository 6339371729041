import {Box, Button, IconButton, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, ReactNode, useState} from "react";
import useApiCreateMachineContentRow from "../../../../api-hooks/MachineContentRow/useApiCreateMachineContentRow";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import {
    machineContentRowFormCharLimits,
} from "../../../../../../../utils/content/content-formLimits";
import useApiGetContentTypes from "../../../../api-hooks/ContentType/useApiGetContentTypes";
import useApiUploadMachineContentRowFile
    from "../../../../api-hooks/MachineContentRow/useApiUploadMachineContentRowFile";
import FadeInOutMessage from "../../../../../../../ui/FadeInOutMessage";
import {RiImageAddLine} from "react-icons/ri";

interface Props {
    machine: {
        id: number
    }
    machineContent: {
        id: number
    }
    setIsSubmitting: (isSubmitting: boolean) => void
}

const ContentPairerRowInput = ({machine, machineContent, setIsSubmitting}: Props) => {
    const [selectedContentTypeId, setSelectedContentTypeId] = useState<number>()
    const [name, setName] = useState<string>("")
    const [contentRowText, setContentRowText] = useState<string>("")
    const [contentRowFile, setContentRowFile] = useState<File | string | null>(null)
    const [contentRowFileName, setContentRowFileName] = useState<string>("")

    const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
        // setContentRowFile(null)
        setContentRowFileName("")
        setContentRowText("")
        if (!e.target.files && e.target.value) {
            setContentRowText(e.target.value);
        } else if (e.target.files) {
            setContentRowFile(e.target.files[0]);
            setContentRowFileName(e.target.files[0].name)
        }
    };

    const {data: fetchedContentTypes, error: fetchedContentTypesError} = useApiGetContentTypes()
    if (fetchedContentTypesError) {
        const axiosError = fetchedContentTypesError as CustomAxiosError;
        console.log(axiosError);
    }

    const contentTypeNamesObj: Record<number, string> | undefined = fetchedContentTypes?.reduce((acc, cur) => ({
        ...acc,
        [cur.id]: cur.name
    }), {})

    const contentTypesObj: Record<number, string | undefined> | undefined = fetchedContentTypes?.reduce((acc, cur) => ({
        ...acc,
        [cur.id]: cur.type
    }), {})

    const {
        mutateAsync: createMachineContentRow,
        isSuccess: createMachineContentRowSuccess
    } = useApiCreateMachineContentRow()
    const {mutateAsync: createMachineContentRowFile} = useApiUploadMachineContentRowFile()

    const handleCreateMachineContentRow = async () => {
        setIsSubmitting(true)

        const fileObj = new FormData();
        fileObj.append("machine_content", contentRowFile as Blob);

        try {
            const machineContentRowPayload = {
                name: name,
                content_type_id: selectedContentTypeId,
                content: contentRowText
            };
            const machineContentRowResponse = await createMachineContentRow({
                machineId: machine.id,
                machineContentId: machineContent.id,
                payload: machineContentRowPayload,
            })
            if (typeof contentRowFile === "object") {
                await createMachineContentRowFile({
                    machineId: machine.id,
                    machineContentId: machineContent.id,
                    machineContentRowId: machineContentRowResponse.id,
                    payload: fileObj,
                })
            }
            setName("")
            setContentRowText("")
            // setContentRowFile(null)
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    return (
        <Box component={"form"}>
            <Stack sx={{width: "100%"}} gap={2}>
                <Stack direction={"row"} gap={0.5}>
                    {(() => {
                        if (contentTypeNamesObj) {
                            const contentTypeIds: string[] = Object.keys(contentTypeNamesObj)
                            const contentTypeNames: string[] = Object.values(contentTypeNamesObj)

                            const menuItems: ReactNode[] = contentTypeIds.map((contentTypeId, index) => {
                                return (
                                    <MenuItem key={index} value={contentTypeId}>
                                        {contentTypeNames[index]}
                                    </MenuItem>
                                )
                            })

                            menuItems.push(
                                <MenuItem
                                    key={menuItems.length}
                                    value={0}
                                    disabled
                                >
                                    <Typography color={"gray"}>Contenuto da visualizzare come*</Typography>
                                </MenuItem>
                            );

                            return (
                                <TextField
                                    label={selectedContentTypeId && selectedContentTypeId > 0 ? "Contenuto da visualizzare come" : ""}
                                    select={true}
                                    sx={{
                                        minWidth: "20rem",
                                        mt: selectedContentTypeId && selectedContentTypeId > 0 ? 0 : 2
                                    }}
                                    required={true}
                                    value={selectedContentTypeId || 0}
                                    onChange={(event) => {
                                        setSelectedContentTypeId(event.target.value as any as number)
                                        setContentRowFileName("")
                                    }}
                                >
                                    {menuItems}
                                </TextField>
                            )
                        }
                    })()}
                    <TextField
                        fullWidth={true}
                        required={!!selectedContentTypeId}
                        label="Titolo contenuto"
                        placeholder={"Questo titolo descriverà agli utenti dell'app questo contenuto. Sceglilo bene, e scrivilo in inglese!"}
                        disabled={!selectedContentTypeId}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        inputProps={{
                            maxLength: machineContentRowFormCharLimits.name,
                        }}
                    />
                </Stack>
                {!(contentTypesObj && selectedContentTypeId)
                    ? <TextField
                        fullWidth={true}
                        label={"Contenuto"}
                        required={!!selectedContentTypeId}
                        disabled={!selectedContentTypeId}
                        variant="standard"
                    />
                    : contentTypesObj[selectedContentTypeId] === "pdf"
                        ? <Stack
                            gap={1}
                            justifyContent={"space-around"}
                            sx={{
                                overflowWrap: "break-word",
                                wordBreak: "break-all"
                            }}
                        >
                            <input
                                style={{display: 'none'}}
                                id="button-pdf"
                                accept={"application/pdf"}
                                type="file"
                                required={!!selectedContentTypeId}
                                onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                            />
                            <Stack
                                direction={"row"}
                                component={"label"}
                                htmlFor="button-pdf"
                                alignItems={"end"}
                                sx={{width: "100%", borderBottom: "1px solid gray", pb: 1}}
                                gap={1}
                            >
                                <Button
                                    variant="contained"
                                    component="span"
                                    disableElevation={true}
                                    size={"small"}
                                >
                                    Carica PDF
                                </Button>
                                <Typography
                                    color={"gray"}
                                    sx={{maxHeight: "2rem", overflowY: "scroll"}}
                                >
                                    {contentRowFileName ? contentRowFileName : "Selezione un documento PDF"}
                                </Typography>
                            </Stack>
                        </Stack>
                        : contentTypesObj[selectedContentTypeId] === "img"
                            ? <Stack
                                gap={1}
                                justifyContent={"space-around"}
                                sx={{
                                    overflowWrap: "break-word",
                                    wordBreak: "break-all"
                                }}
                            >
                                <input
                                    style={{display: 'none'}}
                                    id="button-input-img"
                                    accept={"image/png, image/gif, image/jpeg"}
                                    type="file"
                                    onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                                />
                                <Stack
                                    direction={"row"}
                                    component={"label"}
                                    htmlFor="button-input-img"
                                    alignItems={"end"}
                                    sx={{width: "100%", borderBottom: "1px solid gray", pb: 1}}
                                    gap={1}
                                >
                                    <IconButton component="span">
                                        <RiImageAddLine/>
                                    </IconButton>
                                    <Typography
                                        color={"gray"}
                                        sx={{maxHeight: "2rem", overflowY: "scroll"}}
                                    >
                                        {contentRowFileName ? contentRowFileName : "Immagine macchinario "}
                                    </Typography>
                                </Stack>
                            </Stack>
                            : contentTypesObj[selectedContentTypeId] === "yt" || contentTypesObj[selectedContentTypeId] === "link"
                                ? <TextField
                                    type={"url"}
                                    fullWidth={true}
                                    label={
                                        (contentTypesObj[selectedContentTypeId] === "yt" && "Link di youtube") ||
                                        (contentTypesObj[selectedContentTypeId] === "link" && "Link") ||
                                        ""
                                    }
                                    required={!!selectedContentTypeId}
                                    value={contentRowText}
                                    onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                                    variant="standard"
                                    inputProps={{maxLength: machineContentRowFormCharLimits.content}}
                                />
                                : <TextField
                                    type={"text"}
                                    fullWidth={true}
                                    label={"Descrizione"}
                                    required={!!selectedContentTypeId}
                                    value={contentRowText}
                                    multiline
                                    maxRows={10}
                                    onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                                    variant="standard"
                                    inputProps={{maxLength: machineContentRowFormCharLimits.content}}
                                />
                }
            </Stack>
            <Box sx={{display: "flex", justifyContent: "end"}}>
                <FadeInOutMessage
                    show={createMachineContentRowSuccess}
                    message={"Contenuto aggiunto!"}
                />
                <Button
                    disabled={!(selectedContentTypeId && (name && (contentRowFile || contentRowText)))}
                    variant={"contained"}
                    sx={{my: 2}}
                    onClick={handleCreateMachineContentRow}
                >
                    <Typography variant={"button"}>
                        Aggiungi
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}

export default ContentPairerRowInput;
