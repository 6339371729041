import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IRegistryUser} from "../../../../../utils/interfaces/Registry/RegistryUser/IRegistryUser";


async function doGet(registryId:number | undefined): Promise<IRegistryUser[] | undefined> {
    try {
        const response = await API.get(`/backoffice/registry/${registryId}/registry_user/`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetRegistryUsers = (registryId:number): UseQueryResult<IRegistryUser[] | undefined, CustomAxiosError> => {
    return useQuery<IRegistryUser[] | undefined, CustomAxiosError>(
        ["getRegistryUsers", registryId],
        () => doGet(registryId),
        {
            refetchOnWindowFocus:false,
        }
    );
};

export default useApiGetRegistryUsers;
