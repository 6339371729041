import {Box, Button, IconButton, LinearProgress, Stack, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import useApiCreateMachine from "../../api-hooks/useApiCreateMachine";
import useApiUpdateMachine from "../../api-hooks/useApiUpdateMachine";
import {CustomAxiosError} from "../../../../logics/api_config";
import {machineFormCharLimits} from "../../../../utils/content/content-formLimits";
import useApiUploadMachineFile from "../../api-hooks/useApiUploadMachineFile";
import {IMachine} from "../../../../utils/interfaces/Machine/IMachine";
import {RiImageAddFill, RiImageAddLine} from "react-icons/ri";
import WindowTooltip from "../../../../ui/WindowTooltip";

interface Props {
    machine?: IMachine
    onPostIdChange: (id: number) => void;
}

const MachineEditingForm = ({machine, onPostIdChange}: Props) => {
    const ENDPOINT = process.env.REACT_APP_ENDPOINT;

    const [name, setName] = useState<string | undefined>(machine?.name);
    const [imgName, setImgName] = useState<string>();
    const [selectedImg, setSelectedImg] = useState<File | null>(null)
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const createMutation = useApiCreateMachine()
    const updateMutation = useApiUpdateMachine();

    const {mutateAsync: createMachineFile} = useApiUploadMachineFile()

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleImgChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImg(e.target.files[0])
            setImgName(e.target.files[0].name)
        }
    };

    const handleCreateMachine = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);

        const fileObj = new FormData();
        fileObj.append("image_url", selectedImg as Blob);

        const payload = {
            name: name || null,
        };
        try {
            const machineResponse = await createMutation.mutateAsync({payload: payload})
            if (selectedImg && machineResponse.id) {
                await createMachineFile({machineId: machineResponse.id, payload: fileObj})
            }
            onPostIdChange(machineResponse.id)
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    const handleConfirmEdit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);

        const fileObj = new FormData();
        fileObj.append("image_url", selectedImg as Blob);

        const payload = {
            name: name || null,
        };
        try {
            if (machine?.id) {
                await updateMutation.mutateAsync({machineId: machine.id, payload: payload});
            }
            if (selectedImg) {
                if (selectedImg && machine?.id) {
                    await createMachineFile({machineId: machine.id, payload: fileObj})
                }
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        setName(machine?.name);
    }, [machine]);

    return (
        <div>
            <Typography>
                Macchinario
            </Typography>
            <Box
                component={"form"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    gap: "1rem"
                }}
                onSubmit={(event) => {
                    machine ? handleConfirmEdit(event) : handleCreateMachine(event)
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "2rem"
                    }}
                >
                    <TextField
                        required={true}
                        label={"Nome macchinario"}
                        name={"Nome macchinario"}
                        value={name || ""}
                        onChange={handleChangeName}
                        inputProps={{maxLength: machineFormCharLimits.name}}
                        sx={{width: "18rem"}}
                    />
                    <Stack
                        gap={1}
                        justifyContent={"space-around"}
                        sx={{
                            overflowWrap: "break-word",
                            wordBreak: "break-all",
                            mt:2,
                            width:"50%",
                        }}
                    >
                        <input
                            style={{display: 'none'}}
                            id="button-dropdown-img"
                            accept={"image/png, image/gif, image/jpeg"}
                            type="file"
                            onChange={(event) => handleImgChange(event as ChangeEvent<HTMLInputElement>)}
                        />
                        <Stack
                            direction={"row"}
                            component={"label"}
                            htmlFor="button-dropdown-img"
                            alignItems={"end"}
                            sx={{width: "100%", borderBottom: "1px solid gray", pb: 1}}
                            gap={1}
                        >
                            <WindowTooltip
                                title={
                                    <Box sx={{maxWidth: "30rem", maxHeight: "30rem"}}>
                                        <Typography>Immagine caricata:</Typography>
                                        <Box
                                            sx={{maxWidth: "20rem", maxHeight: "20rem", borderRadius: "5px", m: 2}}
                                            component={"img"}
                                            src={`${ENDPOINT as string + "/" + machine?.image_url}`}
                                            alt={"Nessuna immagine caricata"}
                                        />
                                    </Box>
                                }
                                placement={"top"}
                            >
                                <IconButton component="span">
                                    {machine?.image_url ? <RiImageAddFill/> : <RiImageAddLine/>}
                                </IconButton>
                            </WindowTooltip>
                            <Typography
                                color={"gray"}
                                sx={{maxHeight: "1.5rem", overflowY: "scroll", width:"14rem"}}
                            >
                                {imgName ? imgName : "Immagine macchinario "}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        disabled={isSaving || (
                            machine?.name === name && !selectedImg
                        )}
                    >
                        {machine
                            ? "Salva"
                            : "Crea macchinario"
                        }
                    </Button>
                </Box>
                {isSaving &&
                    <LinearProgress/>
                }
            </Box>
        </div>
    );
}

export default MachineEditingForm;
