import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {API, CustomAxiosError} from "../../../logics/api_config";

interface ApiResponse {
    is_admin: boolean,
    refresh_token: string,
}

interface LoginForm {
    email: string;
    password: string;
}

async function doPostLogin(data: LoginForm): Promise<ApiResponse> {
    try {
        const response = await API.post('/backoffice/login', data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const useDoPostLogin = (): UseMutationResult<ApiResponse, CustomAxiosError, LoginForm> => {
    const queryClient = useQueryClient();

    return useMutation<ApiResponse, CustomAxiosError, LoginForm>(doPostLogin, {
        onSuccess: () => {
            queryClient.invalidateQueries(["whoAmI"]);
        },
    });
};

export default useDoPostLogin;
