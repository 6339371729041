import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doDelete(registryId: number, registryPlantId: number): Promise<void> {
    try {
        await API.DELETE(`/backoffice/registry/${registryId}/plant/${registryPlantId}`);
    } catch (error) {
        throw error;
    }
}

const useApiDeleteRegistryPlant = (): UseMutationResult<void, CustomAxiosError, { registryId: number; registryPlantId: number }> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { registryId: number; registryPlantId: number }>(
        ({registryId, registryPlantId}) => doDelete(registryId, registryPlantId),
        {
            onSuccess: (data, variables) => {
                const { registryId } = variables;
                if (registryId) {
                    queryClient.invalidateQueries(["getRegistryDetailed", registryId]);
                }
            }
        }
    );
};

export default useApiDeleteRegistryPlant;