import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IRegistryUserDetailed} from "../../../../../utils/interfaces/Registry/RegistryUser/IRegistryUserDetailed";

async function doCreate(registryId: number, payload: any): Promise<IRegistryUserDetailed> {
    try {
        const response = await API.postEncoded(`/backoffice/registry/${registryId}/registry_user`, payload);
        return response.data.data
    } catch (error) {
        throw error;
    }
}

const useApiCreateRegistryUser = (): UseMutationResult<IRegistryUserDetailed, CustomAxiosError, {
    registryId: number;
    payload: any
}> => {
    const queryClient = useQueryClient();
    return useMutation<IRegistryUserDetailed, CustomAxiosError, { registryId: number; payload: any }>(
        ({registryId, payload}) => doCreate(registryId, payload),
        {
            onSuccess: (data, variables) => {
                const {registryId} = variables;
                if (registryId) {
                    queryClient.invalidateQueries(["getRegistryDetailed", registryId]);
                    queryClient.invalidateQueries(["getRegistryUserDetailed", registryId]);
                }
            }
        }
    );
};

export default useApiCreateRegistryUser;