import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IMachineContentDetailed} from "../../../../../utils/interfaces/Machine/MachineContent/IMachineContentDetailed";

async function doCreate(machineId: number, payload: any): Promise<IMachineContentDetailed> {
    try {
        const response = await API.postEncoded(`/backoffice/machine/${machineId}/machine_content`, payload);
        return response.data.data
    } catch (error) {
        throw error;
    }
}

const useApiCreateMachineContent = (): UseMutationResult<IMachineContentDetailed, CustomAxiosError, {
    machineId: number;
    payload: any
}> => {
    const queryClient = useQueryClient();
    return useMutation<IMachineContentDetailed, CustomAxiosError, { machineId: number; payload: any }>(
        ({machineId, payload}) => doCreate(machineId, payload),
        {
            onSuccess: (data, variables) => {
                const {machineId} = variables;
                if (machineId) {
                    queryClient.invalidateQueries(["getMachineContents", machineId]);
                    queryClient.invalidateQueries(["getMachineContentDetailed"]);

                }
            }
        }
    );
};

export default useApiCreateMachineContent;