import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import LoginScreen from "../components/Login/LoginScreen";
import BasePage from "../ui/BasePage";
import RegistryPage from "../components/Registry/RegistryPage";
import ProtectedRoute from "./ProtectedRoute";
import MachinesPage from "../components/Machines/MachinesPage";

const Navigation = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route index path="/" element={
                <ProtectedRoute>
                    <BasePage/>
                    <Navigate to={"/registry"}/>
                </ProtectedRoute>
            }/>
            <Route path="/" element={<BasePage/>}>
                <Route index path="/registry" element={
                    <ProtectedRoute>
                        <RegistryPage/>
                    </ProtectedRoute>
                }/>
                <Route path="/machines" element={
                    <ProtectedRoute>
                        <MachinesPage/>
                    </ProtectedRoute>
                }/>
            </Route>
        </Routes>
    );
};

export default Navigation;
