// useGetWhoAmI.ts
import {useQuery, UseQueryResult} from "react-query";
import {IUser} from "../interfaces/IUser";
import {API, ApiResponseBase, CustomAxiosError} from "../../logics/api_config";


export interface ApiResponse extends ApiResponseBase {
    data: IUser;
}

async function whoAmI(): Promise<IUser> {
    try {
        const response = await API.get(`/api/whoami`);
        const responseData: ApiResponse = response?.data;
        return responseData.data;
    } catch (error) {
        throw error;
    }
}

const useGetWhoAmI = (): UseQueryResult<IUser, CustomAxiosError> => {

    return useQuery<IUser, CustomAxiosError>({
        queryFn: whoAmI,
        queryKey: ["whoAmI"],
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: false
    });
};

export default useGetWhoAmI;
