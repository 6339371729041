import React, {useEffect, } from 'react';
import LoginForm from "./LoginForm";
import {Box, Typography} from '@mui/material';
import {useQueryClient} from "react-query";

const LoginScreen = () => {
    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries(["whoAmI"])
    }, []);

    return (
        <div className="flex-1 flex flex-col items-center">
            <div>
                <Box
                    component={"img"}
                    sx={{
                        mt: "1.5rem",
                        display: "flex",
                        justifyContent: "center",
                        width: "13rem",
                    }}
                    src={require("../../assets/alpe-logo-nuovo-def-2.png")}
                    alt={"ALPE"}
                />
                <Typography sx={{fontSize: "1.2rem", mt: -1}}>ALPEGROUP BACKOFFICE</Typography>
            </div>
            <LoginForm/>
        </div>
    );
};

export default LoginScreen;
