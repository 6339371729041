import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doDelete(machineId: number, machineContentId: number, machineContentRowId: number): Promise<void> {
    try {
        await API.DELETE(`/backoffice/machine/${machineId}/machine_content/${machineContentId}/machine_content_row/${machineContentRowId}`);
    } catch (error) {
        throw error;
    }
}

const useApiDeleteMachineContentRow = (): UseMutationResult<void, CustomAxiosError, { machineId: number; machineContentId: number ,machineContentRowId:number}> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { machineId: number; machineContentId: number, machineContentRowId:number }>(
        ({machineId, machineContentId, machineContentRowId}) => doDelete(machineId, machineContentId,machineContentRowId),
        {
            onSuccess: (data, variables) => {
                const { machineContentId } = variables;
                if (machineContentId) {
                    queryClient.invalidateQueries(["getMachineContentDetailed", machineContentId]);
                    queryClient.invalidateQueries(["getMachineContentRows"]);
                }
            }
        }
    );
};

export default useApiDeleteMachineContentRow;