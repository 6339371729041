import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doUpdate(userId: number, payload: any): Promise<void> {
    try {
        await API.putEncoded(`/backoffice/user/${userId}`, payload);
    } catch (error) {
        throw error;
    }
}

const useApiUpdateUser = (): UseMutationResult<void, CustomAxiosError, { userId: number; payload: any }> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { userId: number; payload: any }>(
        ({userId, payload}) => doUpdate(userId, payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getRegistryDetailed"]);
                queryClient.invalidateQueries(["getRegistryUserDetailed"]);
                queryClient.invalidateQueries(["getRegistryPlantUsers"]);
            }
        }
    );
};

export default useApiUpdateUser;