import { useQuery, UseQueryResult } from 'react-query';
import { API, CustomAxiosError } from '../../../../../logics/api_config';
import { IPlantDetailed } from '../../../../../utils/interfaces/Registry/RegistryPlant/IPlantDetailed';

async function doGet(
    registryId: number,
    registryPlantId: number | undefined
): Promise<IPlantDetailed | undefined> {
    try {
        const response = await API.get(`/backoffice/registry/${registryId}/plant/${registryPlantId}`);
        return response.data.data[0];
    } catch (error) {
        const ax_error = error as CustomAxiosError;
        // Throw custom error if you want to handle it differently
        throw ax_error.response?.data || ax_error.message;
    }
}

const useApiGetRegistryPlantDetailed = (
    registryId: number,
    registryPlantId: number | undefined
): UseQueryResult<IPlantDetailed | undefined, CustomAxiosError> => {
    return useQuery<IPlantDetailed | undefined, CustomAxiosError>(
        ['getRegistryPlantDetailed', registryPlantId],
        () => doGet(registryId, registryPlantId),
        {
            enabled: !!registryPlantId,
            refetchOnWindowFocus:false,

        }
    );
};

export default useApiGetRegistryPlantDetailed;
