import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IMachineContent} from "../../../../../utils/interfaces/Machine/MachineContent/IMachineContent";


async function doGet(machineId:number | undefined): Promise<IMachineContent[] | undefined> {
    try {
        const response = await API.get(`/backoffice/machine/${machineId}/machine_content/`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetMachineContents = (machineId:number): UseQueryResult<IMachineContent[] | undefined, CustomAxiosError> => {
    return useQuery<IMachineContent[] | undefined, CustomAxiosError>(
        ["getMachineContents", machineId],
        () => doGet(machineId),
        {
            refetchOnWindowFocus:false,
        }
    );
};

export default useApiGetMachineContents;
