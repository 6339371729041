import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../utils/contexts/auth-context";
import usePostLogin from "./api-hooks/usePostLogin";
import {CustomAxiosError} from "../../logics/api_config";
import {Box, Button, LinearProgress, Paper, Slide, Stack, TextField, Typography} from "@mui/material";
import {RiLoginBoxLine} from "react-icons/ri";

const LoginForm = () => {
    const navigate = useNavigate();
    const {setIsAuthenticated} = useAuth();
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>("")

    const slideContainerRef = React.useRef<HTMLElement>(null);

    const [loading, setLoading] = useState(false);

    const loginMutation = usePostLogin();
    const signin = async (username: string, password: string) => {
        setLoginErrorMessage("");
        try {
            const response = await loginMutation.mutateAsync({
                email: username,
                password: password,
            });
            if (response.is_admin) {
                setIsAuthenticated(true);
                navigate('/registry');
            } else {
                setLoginErrorMessage("Le credenziali non sono state riconosciute")
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            console.log(axiosError)
            setLoginErrorMessage("Le credenziali non sono state riconosciute")
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        let user = e.currentTarget.user.value;
        let password = e.currentTarget.password.value;

        if (user && password) {
            await signin(user, password);
        }
        setLoading(false);
    }

    return (
        <>
        <Paper
            sx={{
                p: 4,
                backgroundColor: '#f0f0f0',
                borderRadius: 4,
                mt: '2rem',
            }}
            component="form"
            onSubmit={handleSubmit}
        >
            <Typography
                sx={{
                    fontWeight: 400,
                    textTransform: 'uppercase',
                    fontSize: '1rem',
                    color: '#8a8a8a',
                    textAlign: 'center',
                    pb: 2,
                }}
            >
                Effettua l'accesso
            </Typography>
            <Stack gap={1}>
                <TextField label="Utente" name="user" required={true}/>
                <TextField label="Password" name="password" required={true} type="password"/>
                <div>
                    {loading
                        ? <div>
                            <span>Accesso in corso...</span>
                            <LinearProgress/>
                        </div>
                        : <Button
                            sx={{ width: '100%', mt: 2 }}
                            type="submit"
                            variant="contained"
                            startIcon={<RiLoginBoxLine/>}
                        >
                            Accedi
                        </Button>
                    }
                </div>
            </Stack>
        </Paper>
            <Box sx={{mt:3}} ref={slideContainerRef}>
            {loginErrorMessage &&
                    <Slide direction={"down"} in={!!loginErrorMessage} container={slideContainerRef.current}>
                            <Typography
                                sx={{fontStyle: "italic", color:"gray"}}
                                variant={"body2"}
                            >
                                {loginErrorMessage}
                            </Typography>
                    </Slide>
            }
            </Box>
        </>
    );
};

export default LoginForm;
