import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IRegistryPlantUser} from "../../../../../utils/interfaces/Registry/RegistryPlant/IRegistryPlantUser";

async function doGet(registryId:number, registryPlantId: number): Promise<IRegistryPlantUser[] | undefined> {
    try {
        const response = await API.get(`/backoffice/registry/${registryId}/plant/${registryPlantId}/registry_plant_user`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetRegistryPlantUsers = (registryId:number, registryPlantId:number): UseQueryResult<IRegistryPlantUser[] | undefined, CustomAxiosError> => {
    return useQuery<IRegistryPlantUser[] | undefined, CustomAxiosError>(
        ["getRegistryPlantUsers", registryPlantId],
        () => doGet(registryId, registryPlantId),
        {
            refetchOnWindowFocus:false,
        }
    );
};

export default useApiGetRegistryPlantUsers;
