import {Autocomplete, Box, Button, Stack, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import {IMachine} from "../../../../../../../utils/interfaces/Machine/IMachine";
import useApiCreateRegistryPlantMachine
    from "../../../../api-hooks/RegistryPlantMachine/useApiCreateRegistryPlantMachine";
import {machineFormCharLimits} from "../../../../../../../utils/content/content-formLimits";
import FadeInOutMessage from "../../../../../../../ui/FadeInOutMessage";

interface Props {
    registry: {
        id: number,
    }
    registryPlantId: number,
    machines: IMachine[]
    setIsSubmitting: (isSubmitting: boolean) => void
}

const PlantPairerMachineInput = ({
                                     registry,
                                     registryPlantId,
                                     machines,
                                     setIsSubmitting
                                 }: Props) => {
    const [selectedMachine, setSelectedMachine] = useState<IMachine | null>()
    const [serialNumber, setSerialNumber] = useState<string>("")
    const [description, setDescription] = useState<string>("")

    const {mutateAsync:createRegistryPlantMachine, isSuccess: createRegistryPlantMachineSuccess} = useApiCreateRegistryPlantMachine();

    const handleCreateRegistryPlantMachine = async () => {
        setIsSubmitting(true)
        try {
            const payload = {
                machine_id: selectedMachine?.id,
                serial_number: serialNumber,
                description: description,
            };
            await createRegistryPlantMachine({
                registryId: registry.id,
                registryPlantId: registryPlantId,
                payload: payload
            })
            setSerialNumber("")
            setDescription("")
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    return (
        <Box component={"form"}>
            <Stack sx={{width: "100%"}} gap={1}>
                <Stack direction={"row"}>
                    <Autocomplete
                        fullWidth={true}
                        componentsProps={{
                            popper: {
                                popperOptions: {
                                    placement: "top",
                                    strategy: "fixed"
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo di macchinario"
                            />
                        )}
                        value={selectedMachine}
                        onChange={(event, machineObj) => setSelectedMachine(machineObj)}
                        options={machines}
                        // groupBy={(option) => option.machine}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        // getOptionDisabled={(option) =>
                        //     !!registryPlantMachines.find(registryPlantMachine => registryPlantMachine.machine.id === option.id)
                        // }
                    />
                    <TextField
                        fullWidth={true}
                        required={!!selectedMachine}
                        label="N° matricolazione"
                        disabled={!selectedMachine}
                        value={serialNumber}
                        onChange={(event) => setSerialNumber(event.target.value)}
                        inputProps={{maxLength: machineFormCharLimits.serial_number}}
                    />
                </Stack>
                <TextField
                    fullWidth={true}
                    required={!!selectedMachine}
                    label={"Descrizione"}
                    disabled={!selectedMachine}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    multiline
                    minRows={2}
                    maxRows={10}
                    defaultValue="Default Value"
                    variant="standard"
                    inputProps={{maxLength: machineFormCharLimits.description}}
                />
            </Stack>
            <Box sx={{display:"flex", justifyContent:"end"}}>
                <FadeInOutMessage
                    show={createRegistryPlantMachineSuccess}
                    message={"Macchinario aggiunto!"}
                />
                <Button
                    disabled={!(selectedMachine && serialNumber && description)}
                    variant={"contained"}
                    sx={{
                        my: 2,
                    }}
                    onClick={handleCreateRegistryPlantMachine}
                >
                    <Typography variant={"button"}>
                        Aggiungi
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}

export default PlantPairerMachineInput;
