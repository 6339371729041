import { useQuery, UseQueryResult } from 'react-query';
import { API, CustomAxiosError } from '../../../../../logics/api_config';
import {IRegistryUserDetailed} from "../../../../../utils/interfaces/Registry/RegistryUser/IRegistryUserDetailed";

async function doGet(
    registryId: number,
    registryUserId: number | undefined
): Promise<IRegistryUserDetailed | undefined> {
    try {
        const response = await API.get(`/backoffice/registry/${registryId}/registry_user/${registryUserId}`);
        return response.data.data[0];
    } catch (error) {
        const ax_error = error as CustomAxiosError;
        throw ax_error.response?.data || ax_error.message;
    }
}

const useApiGetRegistryUserDetailed = (
    registryId: number,
    registryUserId: number | undefined
): UseQueryResult<IRegistryUserDetailed | undefined, CustomAxiosError> => {
    return useQuery<IRegistryUserDetailed | undefined, CustomAxiosError>(
        ['getRegistryDetailed', registryUserId],
        () => doGet(registryId, registryUserId),
        {
            enabled: !!registryUserId,
            refetchOnWindowFocus:false,
        }
    );
};

export default useApiGetRegistryUserDetailed;
