export const registryFormCharLimits = {
    business_name: 255,
    address: 90,
    city: 45,
    cap: 7,
    nation: 255,
}

export const plantFormCharLimits = {
    name: 255,
    address: 90,
    city: 45,
    cap: 7,
    nation: 255,
}

export const userFormCharLimits = {
    username: 45,
    email: 45,
    password: 255,
}

export const machineFormCharLimits = {
    name: 45,
    serial_number: 45,
    description: 65535,
}

export const machineContentFormCharLimits = {
    name: 45,
}
export const machineContentRowFormCharLimits = {
    name: 45,
    content: 65535
}
