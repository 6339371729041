import {useMutation, UseMutationResult} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IUser} from "../../../../../utils/interfaces/IUser";

async function doCreate(payload: any): Promise<IUser> {
    try {
        const response = await API.postEncoded(`/api/user`, payload);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiCreateUser = (): UseMutationResult<IUser, CustomAxiosError, { payload: any }> => {
    return useMutation<IUser, CustomAxiosError, { payload: any }>(
        ({payload}) => doCreate(payload),
    );
};

export default useApiCreateUser;