import React, {ReactNode} from "react";
import {ImCogs} from "react-icons/im";
import {RiBuilding3Fill} from "react-icons/ri";

interface NavbarEntries{
    name: string,
    to: string,
    icon: ReactNode,
}

export const contentNavbarEntries:readonly NavbarEntries[] = [
    {
        name: "Aziende",
        to: "/registry",
        icon:<RiBuilding3Fill/>
    },
    {
        name: "Macchinari",
        to: "/machines",
        icon:<ImCogs/>
    },
];

// STAGING LINK :