import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../logics/api_config";
import {IRegistryDetailed} from "../../../utils/interfaces/Registry/IRegistryDetailed";

async function doGet(registryId:number | undefined): Promise<IRegistryDetailed | undefined> {
    try {
        const response = await API.get(`/backoffice/registry/${registryId}`);
        return response.data.data[0];
    } catch (error) {
        const ax_error = error as CustomAxiosError
        throw error;
    }
}

const useApiGetRegistryDetailed = (registryId:number | undefined): UseQueryResult<IRegistryDetailed | undefined, CustomAxiosError> => {
    return useQuery<IRegistryDetailed | undefined, CustomAxiosError>(
        ["getRegistryDetailed", registryId],
        () => doGet(registryId),
        {
            enabled: !!registryId,
            refetchOnWindowFocus:false,
        }
    );
};

export default useApiGetRegistryDetailed;
