import axios from 'axios';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
let refreshPromise = null;

const response_interceptor = (instance) => {
    instance.defaults.withCredentials = true; // Imposta withCredentials su true per gestire i cookie

    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
            return Promise.reject(error);
        }
    );
};

export default response_interceptor;
