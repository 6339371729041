import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IMachineContentDetailed} from "../../../../../utils/interfaces/Machine/MachineContent/IMachineContentDetailed";

async function doUpdate(machineId: number, machineContentId: number, payload: any): Promise<IMachineContentDetailed> {
    try {
        const response = await API.putEncoded(`/backoffice/machine/${machineId}/machine_content/${machineContentId}`, payload);
        return response.data.data
    } catch (error) {
        throw error;
    }
}

const useApiUpdateMachineContent = (): UseMutationResult<IMachineContentDetailed, CustomAxiosError, {
    machineId: number;
    machineContentId: number;
    payload: any
}> => {
    const queryClient = useQueryClient();
    return useMutation<IMachineContentDetailed, CustomAxiosError, {
        machineId: number;
        machineContentId: number;
        payload: any
    }>(
        ({machineId, machineContentId, payload}) => doUpdate(machineId, machineContentId, payload),
        {
            onSuccess: (data, variables) => {
                const {machineContentId} = variables;
                if (machineContentId) {
                    queryClient.invalidateQueries(["getMachineContentDetailed", machineContentId]);
                    queryClient.invalidateQueries(["getMachineContentRows"]);

                }
            }
        }
    );
};

export default useApiUpdateMachineContent;