import React from 'react';
import {ProvideAuth} from "../utils/contexts/auth-context";
import {ToastProvider} from "../utils/contexts/toast-context";
import {QueryClient, QueryClientProvider} from "react-query";
import {ThemeProvider} from "@mui/material";
import {THEMES} from "../utils/themes/global-theme";


type Props = {
    children: React.ReactNode;
}

const queryCLient = new QueryClient()
const MainProviders = ({children}: Props) => {
    return (
        <QueryClientProvider client={queryCLient}>
            <ProvideAuth>
                <ThemeProvider theme={THEMES.themeGlobal}>
                    <ToastProvider>
                        {children}
                    </ToastProvider>
                </ThemeProvider>
            </ProvideAuth>
        </QueryClientProvider>
    );
};

export default MainProviders;