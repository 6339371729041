import {Box, Button, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {machineContentFormCharLimits,} from "../../../../../../utils/content/content-formLimits";
import {
    IMachineContentDetailed
} from "../../../../../../utils/interfaces/Machine/MachineContent/IMachineContentDetailed";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiCreateMachineContent from "../../../api-hooks/MachineContent/useApiCreateMachineContent";
import useApiUpdateMachineContent from "../../../api-hooks/MachineContent/useApiUpdateMachineContent";
import {categoryNamesIta} from "../../../../../../utils/interfaces/ICategory";
import useApiGetCategories from "../../../api-hooks/Category/useApiGetCategories";

interface Props {
    machine: {
        id: number,
    },
    machineContent?: IMachineContentDetailed,
    onPostIdChange: (id: number) => void,
}

const ContentManagerTableEditingForm = ({
                                            machine,
                                            machineContent,
                                            onPostIdChange
                                        }: Props) => {
    const [name, setName] = useState<string | undefined>(machineContent?.name)
    const [categoryId, setCategoryId] = useState<number>(machineContent?.category.id ?? 0)
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const {data: fetchedCategories, error: fetchedCategoriesError} = useApiGetCategories()
    if (fetchedCategoriesError) {
        const axiosError = fetchedCategoriesError as CustomAxiosError;
        console.log(axiosError);
    }

    const {mutateAsync: createMachineContent} = useApiCreateMachineContent();
    const {mutateAsync: updateMachineContent} = useApiUpdateMachineContent();

    const handleSetName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const handleSetCategoryId = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCategoryId(e.target.value as any as number)
    }

    const handleCreateMachineContent = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        try {
            const payloadContent = {
                name: name,
                category_id: categoryId,
            };
            const responseMachineContent = await createMachineContent({machineId: machine.id, payload: payloadContent})
            onPostIdChange(responseMachineContent.id)
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    const handleConfirmEdit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        try {
            const payloadContent = {
                name: name,
                category_id: categoryId,
            };
            if (machineContent?.id) {
                await updateMachineContent({
                    machineId: machine.id,
                    machineContentId: machineContent.id,
                    payload: payloadContent
                })
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        setName(machineContent?.name);
        setCategoryId(machineContent?.category.id ?? 0);
    }, [machineContent]);

    return (
        <div>
            <Typography>
                Argomento {machineContent?.name}
            </Typography>
            <Box
                component={"form"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "1rem",
                    mt: 1,
                }}
                onSubmit={(event) => {
                    machineContent ? handleConfirmEdit(event) : handleCreateMachineContent(event)
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        gap: "0.5rem"
                    }}
                >
                    <TextField
                        label="Titolo argomento"
                        placeholder={"Questo titolo raggruppa i vari contenuti e verrà mostrato agli utenti dell'app. Sceglilo bene, e scrivilo in inglese!"}
                        sx={{width: "50rem"}}
                        required={true}
                        value={name || ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleSetName(e)
                        }}
                        disabled={isSaving}
                        inputProps={{
                            maxLength: machineContentFormCharLimits.name,
                            // sx: {mb: 2}
                        }}
                    />
                    <TextField
                        value={categoryId || 0}
                        label={categoryId > 0 ? "Da elencare in app come" : ""}
                        required={true}
                        select={true}
                        onChange={(event) => handleSetCategoryId(event)}
                        sx={{
                            width: "24rem",
                            mt: categoryId > 0 ? 0 : 2
                        }}
                    >
                        {fetchedCategories?.map((category) => (
                            <MenuItem
                                key={category.id}
                                value={category.id}
                            >
                                {categoryNamesIta[category.id - 1]}
                            </MenuItem>
                        ))}
                        <MenuItem
                            key={0}
                            value={0}
                            disabled
                        >
                            <Typography color={"#656565"}>
                                Da elencare in app come*
                            </Typography>
                        </MenuItem>
                    </TextField>
                </Box>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        disabled={isSaving || (
                            ((name === undefined || name === "") || categoryId === 0) ||
                            (machineContent?.name === name && machineContent?.category.id === categoryId)
                        )}
                    >
                        {machineContent
                            ? "Salva"
                            : "Crea argomento"
                        }
                    </Button>
                </Box>
                {isSaving &&
                    <LinearProgress/>
                }
            </Box>
        </div>
    );
}

export default ContentManagerTableEditingForm;
