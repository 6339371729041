import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../logics/api_config";

async function doUpdate(registryId: number, payload: any): Promise<void> {
    try {
        await API.putEncoded(`/backoffice/registry/${registryId}`, payload);
    } catch (error) {
        throw error;
    }
}

const useApiUpdateRegistry = (): UseMutationResult<void, CustomAxiosError, { registryId: number; payload: any }> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { registryId: number; payload: any }>(
        ({registryId, payload}) => doUpdate(registryId, payload),
        {
            onSuccess: (data,variables) => {
                if(variables) {
                    const registryId = variables.registryId
                    queryClient.invalidateQueries(["getRegistries"])
                    queryClient.invalidateQueries(["getRegistryDetailed", registryId])
                }
            }

        }
    );
};

export default useApiUpdateRegistry;