import {Box, Checkbox, Divider, FormControlLabel, LinearProgress, Stack, Typography,} from "@mui/material";
import React, {Fragment, useState} from "react";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import useApiCreateRegistryPlantUser from "../../../../api-hooks/RegistryPlantUser/useApiCreateRegistryPlantUser";
import useApiGetRegistryPlantUsers from "../../../../api-hooks/RegistryPlantUser/useApiGetRegistryPlantUsers";
import useApiDeleteRegistryPlantUser from "../../../../api-hooks/RegistryPlantUser/useApiDeleteRegistryPlantUser";
import useApiGetRegistryUsers from "../../../../api-hooks/RegistryUser/useApiGetRegistryUsers";

interface Props {
    registry: {
        id: number,
    },
    plant: {
        id: number,
    }
}

const PlantPairerUserFields = ({registry, plant}: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const {mutateAsync: createRegistryPlantUser} = useApiCreateRegistryPlantUser()
    const {mutateAsync: deleteRegistryPlantUser} = useApiDeleteRegistryPlantUser();

    const {data: fetchedRegistryUsers, error: fetchedRegistryUsersError} = useApiGetRegistryUsers(registry.id)
    if (fetchedRegistryUsersError) {
        const axiosError = fetchedRegistryUsersError as CustomAxiosError;
        console.log(axiosError);
    }

    const {
        data: fetchedRegistryPlantUsers,
        error: registryPlantUsersError
    } = useApiGetRegistryPlantUsers(registry.id, plant.id)
    if (registryPlantUsersError) {
        const axiosError = registryPlantUsersError as CustomAxiosError;
        console.log(axiosError);
    }


    const handleCreateRegistryPlantUser = async (registryUserId: number) => {
        setIsSubmitting(true)
        try {
            const payload = {
                registry_user_id: registryUserId
            };
            await createRegistryPlantUser({
                registryId: registry.id,
                registryPlantId: plant.id,
                payload: payload
            })
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    const handleDeleteRegistryPlantUser = async (registryPlantUserId: number) => {
        setIsSubmitting(true)
        try {
            await deleteRegistryPlantUser({
                registryId: registry.id,
                registryPlantId: plant.id,
                registryPlantUserId: registryPlantUserId
            })
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    return (
        <Stack sx={{minHeight: "30rem", borderLeft: "1px solid rgba(224,224,224)"}}>
            <div style={{maxHeight: '30rem', overflowY: 'auto'}}>
                <Stack>
                    {fetchedRegistryUsers?.length
                        ? <>
                            {fetchedRegistryPlantUsers && fetchedRegistryUsers?.map((registryUser) => {
                                const registryPlantUser = fetchedRegistryPlantUsers.find(registryPlantUser =>
                                    registryPlantUser.registry_user.id === registryUser.id
                                );
                                return (
                                    <Fragment key={registryUser.id}>
                                        <Box>
                                            <FormControlLabel
                                                sx={{
                                                    color: registryPlantUser ? 'black' : '#bbbbbb',
                                                }}
                                                label={
                                                    <Box
                                                        sx={{
                                                            minWidth:"25rem",
                                                            maxWidth:"25rem",
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap"
                                                    }}>
                                                        {registryUser.user.email}
                                                    </Box>}
                                                control={
                                                    <Checkbox
                                                        sx={{pl: 2, color: registryPlantUser ? '' : '#d7d5d5',}}
                                                        checked={!!registryPlantUser}
                                                        onClick={() => {
                                                            registryPlantUser
                                                                ? handleDeleteRegistryPlantUser(registryPlantUser.id)
                                                                : handleCreateRegistryPlantUser(registryUser.id);
                                                        }}
                                                    />
                                                }
                                            />
                                            <Divider/>
                                        </Box>
                                    </Fragment>
                                );
                            })}
                        </>
                        : <Typography sx={{color: "#99A1AA", ml: 2, mt: "7rem", fontStyle: "italic"}}>
                            Questa azienda non ha ancora utenti
                        </Typography>
                    }
                </Stack>
            </div>
            {isSubmitting &&
                <LinearProgress/>
            }
        </Stack>
    )
}

export default PlantPairerUserFields;