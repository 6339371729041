import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {IMachineDetailed} from "../../../utils/interfaces/Machine/IMachineDetailed";
import {API, CustomAxiosError} from "../../../logics/api_config";

async function doCreate(machineId: number, payload: FormData): Promise<IMachineDetailed> {
    try {
        const response = await API.postWithFile(`/backoffice/machine/${machineId}/upload_image`, payload);
        return response.data.data
    } catch (error) {
        throw error;
    }
}

const useApiUploadMachineFile = (): UseMutationResult<IMachineDetailed, CustomAxiosError, {
    machineId: number;
    payload: any
}> => {
    const queryClient = useQueryClient();
    return useMutation<IMachineDetailed, CustomAxiosError, { machineId: number; payload: any }>(
        ({
             machineId,
             payload
         }) => doCreate(machineId, payload),
        {
            onSuccess: (data, variables) => {
                const {machineId} = variables;
                if (machineId) {
                    queryClient.invalidateQueries(["getMachineDetailed", machineId]);
                }
            }
        }
    );
};

export default useApiUploadMachineFile;