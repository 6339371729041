import {useQuery, UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IContentType} from "../../../../../utils/interfaces/IContentType";

async function doGet(): Promise<IContentType[] | undefined> {
    try {
        const response = await API.get(`/backoffice/content_type`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetContentTypes = (): UseQueryResult<IContentType[] | undefined, CustomAxiosError> => {
    return useQuery<IContentType[] | undefined, CustomAxiosError>(
        ["getContentTypes"],
        () => doGet(),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export default useApiGetContentTypes;
