import {Box, Grow} from "@mui/material";
import React from "react";

interface Props {
    show: boolean,
    message?: string
}

const FadeInOutMessage = ({show, message = "Success!"}:Props) => {
    return (
        <>
            {show &&
                <Grow in={show}>
                    <Box
                        sx={{
                            display: "flex",
                            color: "#fd761a",
                            alignSelf: "center",
                            mx: 2,
                            fontSize: "1rem",
                            animation: "fadeInOut 6s linear 1 forwards",
                            "@keyframes fadeInOut": {
                                "0%": {
                                    opacity: 0
                                },
                                "3%": {
                                    opacity: 1
                                },
                                "90%": {
                                    opacity: 1
                                },
                                "100%": {
                                    opacity: 0
                                }
                            },
                        }}>
                        {message}
                    </Box>
                </Grow>
            }
        </>
    )
}

export default FadeInOutMessage;