import {
    Box,
    IconButton,
    LinearProgress, Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {ArrowBackIos, ArrowDownward, ArrowUpward} from "@mui/icons-material";
import useApiDeleteMachineContentRow from "../../../../api-hooks/MachineContentRow/useApiDeleteMachineContentRow";
import useApiUpdateMachineContentRow from "../../../../api-hooks/MachineContentRow/useApiUpdateMachineContentRow";
import useApiGetMachineContentRows from "../../../../api-hooks/MachineContentRow/useApiGetMachineContentRows";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import ContentPairerRowInput from "./ContentPairerRowInput";
import ContentPairerRowFieldDropdown from "./ContentPairerRowFieldDropdown";
import {RiEditFill, RiImageFill} from "react-icons/ri";
import {BiCollapseAlt} from "react-icons/bi";
import {TType} from "../../../../../../../utils/interfaces/IContentType";
import WindowTooltip from "../../../../../../../ui/WindowTooltip";
import {TbPdf} from "react-icons/tb";
import {ArrowRightIcon} from "@mui/x-date-pickers";

interface Props {
    machine: {
        id: number
    }
    machineContent: {
        id: number
    }
    isFetching: boolean
}

const ContentPairerRowFields = ({machine, machineContent}: Props) => {
    const ENDPOINT = process.env.REACT_APP_ENDPOINT;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [expandedRow, setExpandedRow] = useState<number | undefined>(undefined)

    const {mutateAsync: deleteMachineContentRow} = useApiDeleteMachineContentRow()
    const {mutateAsync: updateMachineContentRow} = useApiUpdateMachineContentRow()

    const {
        data: fetchedMachineContentRows,
        error: fetchedMachineContentRowsError
    } = useApiGetMachineContentRows(machine.id, machineContent.id)
    if (fetchedMachineContentRowsError) {
        const axiosError = fetchedMachineContentRowsError as CustomAxiosError;
        console.log(axiosError);
    }

    const handleExpandRow = (rowIndex: number) => {
        setExpandedRow(rowIndex !== expandedRow ? rowIndex : undefined)
    }

    const handleDeleteMachineContentRow = async (machineContentRowId: number) => {
        setIsSubmitting(true)
        setExpandedRow(undefined)
        try {
            await deleteMachineContentRow({
                machineId: machine.id,
                machineContentId: machineContent.id,
                machineContentRowId: machineContentRowId,
            })
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    const handleUpdateMachineContentRowPosition = async (machineContentRow: {
        id: number,
        content_type_id: number
    }, machineContentRowNewRowPosition: number) => {
        setIsSubmitting(true)
        setExpandedRow(undefined)
        try {
            await updateMachineContentRow({
                machineId: machine.id,
                machineContentId: machineContent.id,
                machineContentRowId: machineContentRow.id,
                payload: {
                    row_position: machineContentRowNewRowPosition,
                    content_type_id: machineContentRow.content_type_id
                },
            })
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    const handleFormatContent = (content: string, contentType: TType) => {
        const pdfNameRegex = /(?<=\/\d{3}-)[A-Za-z0-9-]+\d{18}\.pdf(?=$)/g
        switch (contentType) {
            case "pdf":
                return pdfNameRegex.exec(content)?.[0]
            default:
                return content
        }
    }

    return (
        <Stack sx={{
            width: "75rem",
            height: "100%",
            borderRight: "1px solid #DFDFDFFF",
            borderLeft: "1px solid #DFDFDFFF"
        }}>
            <Typography>Contenuti</Typography>
            <ContentPairerRowInput
                machine={machine}
                machineContent={machineContent}
                setIsSubmitting={setIsSubmitting}
            />
            <div style={{height: '26rem', overflow: 'auto',}}>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {fetchedMachineContentRows?.map((machineContentRow, index) => (
                                <TableRow
                                    key={machineContentRow.id}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#dee2e2"
                                        }
                                    }}
                                >
                                    <TableCell sx={{width: "62rem", borderRight: "1px solid #b7bcc3"}}>
                                        <Stack sx={{width: "100%"}}>
                                            {expandedRow === index
                                                ? ""
                                                : <Typography>{machineContentRow.name}</Typography>
                                            }
                                            <Typography sx={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                color: "gray",
                                            }}>
                                                {machineContentRow.content_type.name}
                                            </Typography>
                                            {expandedRow === index
                                                ? ""
                                                : machineContentRow.content_type.type === "img"
                                                    ? <Box>
                                                        <WindowTooltip
                                                            title={
                                                                <Box sx={{maxWidth: "30rem", maxHeight: "30rem"}}>
                                                                    <Typography>Immagine caricata:</Typography>
                                                                    <Box
                                                                        sx={{
                                                                            maxWidth: "20rem",
                                                                            maxHeight: "20rem",
                                                                            borderRadius: "5px",
                                                                            m: 2
                                                                        }}
                                                                        component={"img"}
                                                                        src={`${ENDPOINT as string + "/" + machineContentRow.content}`}
                                                                        alt={"Nessuna immagine caricata"}
                                                                    />
                                                                </Box>
                                                            }
                                                            placement={"top"}
                                                        >
                                                            <IconButton component="span">
                                                                <RiImageFill/>
                                                            </IconButton>
                                                        </WindowTooltip>
                                                    </Box>
                                                    : <Typography
                                                        sx={{
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            color: "gray",
                                                            fontSize: "0.8rem",
                                                            maxWidth: "20rem"
                                                        }}
                                                    >
                                                        {(machineContentRow.content_type.type === "link" ||
                                                            machineContentRow.content_type.type === "yt" ||
                                                            machineContentRow.content_type.type === "pdf")
                                                            ? <Link
                                                                href={
                                                                    machineContentRow.content_type.type === "pdf"
                                                                        ? `${ENDPOINT as string + "/" + machineContentRow.content}`
                                                                        : machineContentRow.content
                                                                }
                                                                target="_blank"
                                                                rel="noopener"
                                                            >
                                                                {machineContentRow.content_type.type === "pdf"
                                                                    ? <Stack direction={"row"}>Visualizza
                                                                        PDF <ArrowRightIcon fontSize={"small"}/></Stack>
                                                                    : machineContentRow.content
                                                                }
                                                            </Link>
                                                            : handleFormatContent(machineContentRow.content,
                                                                machineContentRow.content_type.type)
                                                        }
                                                    </Typography>
                                            }
                                            {index === expandedRow &&
                                                <ContentPairerRowFieldDropdown
                                                    setExpandedRow={setExpandedRow}
                                                    machineContentRow={machineContentRow}
                                                    machine={machine}
                                                    machineContent={machineContent}
                                                />
                                            }
                                        </Stack>
                                    </TableCell>
                                    <TableCell sx={{borderLeft: "1px solid gray"}}>
                                        <Stack>
                                            <IconButton
                                                sx={{p: 0, m: 0}}
                                                disabled={machineContentRow.row_position === 1}
                                                size={"small"}
                                                onClick={
                                                    () => {
                                                        handleUpdateMachineContentRowPosition(
                                                            {
                                                                id: machineContentRow.id,
                                                                content_type_id: machineContentRow.content_type.id
                                                            },
                                                            --machineContentRow.row_position
                                                        )
                                                    }
                                                }
                                            >
                                                <ArrowUpward fontSize={"small"}/>
                                            </IconButton>
                                            <IconButton
                                                sx={{p: 0, m: 0}}
                                                size={"small"}
                                                disabled={index === fetchedMachineContentRows.length - 1}
                                                onClick={
                                                    () => {
                                                        handleUpdateMachineContentRowPosition(
                                                            {
                                                                id: machineContentRow.id,
                                                                content_type_id: machineContentRow.content_type.id
                                                            },
                                                            ++machineContentRow.row_position
                                                        )
                                                    }
                                                }
                                            >
                                                <ArrowDownward fontSize={"small"}/>
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => handleExpandRow(index)}
                                        >
                                            {index === expandedRow
                                                ? <BiCollapseAlt/>
                                                : <RiEditFill/>
                                            }

                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => handleDeleteMachineContentRow(machineContentRow.id)}
                                        >
                                            X
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {isSubmitting &&
                <LinearProgress/>
            }
        </Stack>
    )
}

export default ContentPairerRowFields
