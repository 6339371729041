import {Box} from "@mui/material";
import PlantPairerTab from "./PlantManagerTablePairerComponents/PlantPairerTab";

interface Props {
    registry: {
        id: number,
    }
    plant: {
        id: number,
    }
}

const PlantManagerTablePairer = ({registry, plant}: Props) => {

    return (
        <Box sx={{width: "100%"}}>
            <PlantPairerTab
                registry={registry}
                plant={plant}
            />
        </Box>
    )
}

export default PlantManagerTablePairer;