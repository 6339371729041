import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../logics/api_config";
import {IRegistry} from "../../../utils/interfaces/Registry/IRegistry";


async function doGet(): Promise<IRegistry[] | undefined> {
    try {
        const response = await API.get("/backoffice/registry");
        return response.data.data;
    } catch (error) {
        const ax_error = error as CustomAxiosError
        throw error;
    }
}

const useApiGetRegistries = (): UseQueryResult<IRegistry[] | undefined, CustomAxiosError> => {
    return useQuery<IRegistry[] | undefined, CustomAxiosError>(
        ["getRegistries"],
        () => doGet(),
    );
};

export default useApiGetRegistries;
