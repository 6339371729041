import {Box, Collapse, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import useApiGetRegistryDetailed from "../api-hooks/useApiGetRegistryDetailed";
import {CustomAxiosError} from "../../../logics/api_config";
import {IRegistryDetailed} from "../../../utils/interfaces/Registry/IRegistryDetailed";
import RegistryEditingForm from "./RegistryEditorComponents/RegistryEditingForm";
import RegistryManager from "./RegistryEditorComponents/RegistryManager";
import ModalCloseButton from "../../../ui/ModalCloseButton";

interface Props {
    id: number | undefined,
    setIsModalClosed: () => void
}

const RegistryEditorModal = ({id, setIsModalClosed}: Props) => {
    const [registryDetailed, setRegistryDetailed] = useState<IRegistryDetailed>()
    const [postId, setPostId] = useState<number>();

    const handleIdChange = (id: number) => {
        setPostId(id);
    };

    const {data: fetchedRegistry, error: fetchedRegistryError} = useApiGetRegistryDetailed(postId ? postId : id)
    if (fetchedRegistryError) {
        const axiosError = fetchedRegistryError as CustomAxiosError;
        console.log(axiosError);
    }

    useEffect(() => {
        if (fetchedRegistry) {
            setRegistryDetailed(fetchedRegistry)
        }
    }, [fetchedRegistry]);

    return (
        <>
            <ModalCloseButton setIsModalClosed={setIsModalClosed}/>
            <Box
                sx={{
                    backgroundColor:"white",
                    boxShadow: 2,
                    border: '3px solid',
                    borderColor: "primary.dark",
                    borderRadius: "4px",
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}
            >
                <Stack gap={2}>
                    <RegistryEditingForm
                        registry={(registryDetailed)
                            ? {
                                id: registryDetailed.id,
                                business_name: registryDetailed.business_name,
                                address: registryDetailed.address,
                                city: registryDetailed.city,
                                cap: registryDetailed.cap,
                                nation: registryDetailed.nation,
                            }
                            : undefined
                        }
                        onPostIdChange={handleIdChange}
                    />
                    <Collapse in={!!registryDetailed}>
                        {registryDetailed &&
                            <RegistryManager
                                registry={{
                                    id: registryDetailed.id,
                                    address: registryDetailed.address,
                                    city: registryDetailed.city,
                                    cap: registryDetailed.cap,
                                    nation: registryDetailed.nation,
                                    plants: registryDetailed.plants,
                                    registry_users: registryDetailed.registry_users
                                }}
                            />
                        }
                    </Collapse>
                </Stack>
            </Box>
        </>
    )
}

export default RegistryEditorModal;