import {Box, IconButton} from "@mui/material";
import {PiX} from "react-icons/pi";
import React from "react";

interface Props{
    setIsModalClosed: () => void
}

const ModalCloseButton = ({setIsModalClosed}:Props) => {
    return(
        <Box
            sx={{
                display: "flex",
                justifyContent:"flex-end",
                py: 0.2,
            }}
            onClick={setIsModalClosed}
        >
            <Box sx={{
                backgroundColor:"white",
                border:"3px solid",
                borderColor:"primary.dark",
                borderRadius: "4px",
                py:0.5,
                borderTopRightRadius:"18px",
            }}>
                <IconButton>
                    <PiX/>
                </IconButton>
            </Box>
        </Box>
    )
}

export default ModalCloseButton
