import {Box, Button, LinearProgress, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {userFormCharLimits} from "../../../../../../utils/content/content-formLimits";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiCreateUser from "../../../api-hooks/User/useApiCreateUser";
import useApiUpdateUser from "../../../api-hooks/User/useApiUpdateUser";
import useApiCreateRegistryUser from "../../../api-hooks/RegistryUser/useApiCreateRegistryUser";
import {generateComplexPassword} from "./generateComplexPassword";
import {IRegistryUser} from "../../../../../../utils/interfaces/Registry/RegistryUser/IRegistryUser";

interface Props {
    registry: {
        id: number
    }
    registryUser?: Omit<IRegistryUser, "registry">
    onPostIdChange: (id: number) => void;
}

const UserManagerTableEditingForm = ({registry, registryUser, onPostIdChange}: Props) => {
    const [email, setEmail] = useState<string | undefined>(registryUser?.user.email);
    // const [username, seUsername] = useState<string | undefined>(registryUser?.user.username);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const generatedRandomPassLength = 12

    const {mutateAsync: createUser} = useApiCreateUser();
    const {mutateAsync: updateUser} = useApiUpdateUser();
    const {mutateAsync: createRegistryUser} = useApiCreateRegistryUser();

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleCreateNormalAndRegistryUser = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        try {
            const payloadUser = {
                email: email,
                // username: username,
                password: generateComplexPassword(generatedRandomPassLength),
            };
            const responseUser = await createUser({payload: payloadUser})
            const payloadRegistryUser = {
                user_id: responseUser.id
            }
            const responseRegistryUser = await createRegistryUser({
                registryId: registry.id,
                payload: payloadRegistryUser
            })
            onPostIdChange(responseRegistryUser.id)
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    const handleConfirmEdit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        try {
            const payloadUser = {
                email: email,
                // username: username,
                // password: values.password,
            };
            if (registryUser?.id) {
                await updateUser({userId: registryUser.id, payload: payloadUser})
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        setEmail(registryUser?.user.email);
        // setUsername(registryUser?.username);
    }, [registryUser]);

    return (
        <div>
            <Typography>
                Utente dell'azienda
            </Typography>
            <Box
                component={"form"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem"
                }}
                onSubmit={(event) => {
                    registryUser ? handleConfirmEdit(event) : handleCreateNormalAndRegistryUser(event)
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem"
                    }}
                >
                    <TextField
                        required={true}
                        fullWidth={true}
                        label={"Email"}
                        name={"Email"}
                        type={"email"}
                        value={email || ""}
                        onChange={(e:ChangeEvent<HTMLInputElement>) =>{
                            handleChangeEmail(e)
                        }}
                        disabled={isSaving}
                        inputProps={{
                            maxLength: userFormCharLimits.email,
                        }}
                    />
                    {/*<TextField*/}
                    {/*    label={"Username"}*/}
                    {/*    name={"Username"}*/}
                    {/*    value={address || ""}*/}
                    {/*    onChange={handleChangeAddress}*/}
                    {/*    disabled={isSaving}*/}
                    {/*    inputProps={{maxLength: plantFormCharLimits.address}}*/}
                    {/*/>*/}
                </Box>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        disabled={isSaving || (
                            registryUser?.user.email === email
                            // &&
                            // registryUser?.username === username
                        )}
                    >
                        {registryUser
                            ? "Salva"
                            : "Crea utente"
                        }
                    </Button>
                </Box>
                {isSaving &&
                    <LinearProgress/>
                }
            </Box>
        </div>
    );
}

export default UserManagerTableEditingForm;