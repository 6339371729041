import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doDelete(registryId: number,registryPlantId: number, registryPlantMachineId: number): Promise<void> {
    try {
        await API.DELETE(`/backoffice/registry/${registryId}/plant/${registryPlantId}/registry_plant_machine/${registryPlantMachineId}`);
    } catch (error) {
        throw error;
    }
}

const useApiDeleteRegistryPlantMachine = (): UseMutationResult<void, CustomAxiosError, { registryId: number; registryPlantId: number; registryPlantMachineId: number}> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { registryId: number; registryPlantId: number; registryPlantMachineId: number}>(
        ({registryId, registryPlantId, registryPlantMachineId}) => doDelete(registryId, registryPlantId, registryPlantMachineId),
        {
            onSuccess: (data, variables) => {
                const { registryPlantId } = variables;
                if (registryPlantId) {
                    queryClient.invalidateQueries(["getRegistryPlantMachines", registryPlantId]);
                }
            }
        }
    );
};

export default useApiDeleteRegistryPlantMachine;