import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../logics/api_config";
import {IMachineDetailed} from "../../../utils/interfaces/Machine/IMachineDetailed";

async function doGet(machineId:number | undefined): Promise<IMachineDetailed | undefined> {
    try {
        const response = await API.get(`/backoffice/machine/${machineId}`);
        return response.data.data[0];
    } catch (error) {
        const ax_error = error as CustomAxiosError
        throw error;
    }
}

const useApiGetMachineDetailed = (machineId:number | undefined): UseQueryResult<IMachineDetailed | undefined, CustomAxiosError> => {
    return useQuery<IMachineDetailed | undefined, CustomAxiosError>(
        ["getMachineDetailed", machineId],
        () => doGet(machineId),
        {
            enabled: !!machineId,
            refetchOnWindowFocus:false,

        }
    );
};

export default useApiGetMachineDetailed;
