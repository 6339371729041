import React, {ChangeEvent, FormEvent, useState} from "react";
import {Box, Button, IconButton, LinearProgress, Paper, Stack, TextField, Typography} from "@mui/material";
import {IMachineContentRow} from "../../../../../../../utils/interfaces/Machine/MachineContentRow/IMachineContentRow";
import {machineContentRowFormCharLimits} from "../../../../../../../utils/content/content-formLimits";
import useApiUpdateMachineContentRow from "../../../../api-hooks/MachineContentRow/useApiUpdateMachineContentRow";
import useApiUploadMachineContentRowFile
    from "../../../../api-hooks/MachineContentRow/useApiUploadMachineContentRowFile";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import WindowTooltip from "../../../../../../../ui/WindowTooltip";
import {RiImageAddFill, RiImageAddLine} from "react-icons/ri";

interface Props {
    machine: {
        id: number
    }
    machineContent: {
        id: number
    }
    machineContentRow: IMachineContentRow,
    setExpandedRow: (expandedRow: number | undefined) => void
}


const ContentPairerRowFieldDropdown = ({machine, machineContent, machineContentRow, setExpandedRow}: Props) => {
    const ENDPOINT = process.env.REACT_APP_ENDPOINT;
    const pdfNameRegex = /(?<=\/\d{3}-)[A-Za-z0-9-]+\d{18}\.pdf(?=$)/g

    const [name, setName] = useState<string>(machineContentRow.name)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [contentRowText, setContentRowText] = useState<string>(machineContentRow.content)
    const [contentRowFile, setContentRowFile] = useState<File | string | null>(null)
    const [contentRowFileName, setContentRowFileName] = useState<string>("")


    const {mutateAsync: updateMachineContentRow} = useApiUpdateMachineContentRow()
    const {mutateAsync: createMachineContentRowFile} = useApiUploadMachineContentRowFile()

    const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
        // setContentRowFile(null)
        setContentRowFileName("")
        setContentRowText("")

        if (!e.target.files && e.target.value) {
            setContentRowText(e.target.value);
        } else if (e.target.files) {
            setContentRowFile(e.target.files[0]);
            setContentRowFileName(e.target.files[0].name)
        }
    };

    const handleUpdateMachineContentRow = async (e: FormEvent<HTMLFormElement>) => {
        setIsSaving(true)
        e.preventDefault()

        const fileObj = new FormData();
        fileObj.append("machine_content", contentRowFile as Blob);

        try {
            let machineContentRowPayload: Object
            if (machineContentRow.content_type.type === "pdf" || machineContentRow.content_type.type === "img") {
                await createMachineContentRowFile({
                    machineId: machine.id,
                    machineContentId: machineContent.id,
                    machineContentRowId: machineContentRow.id,
                    payload: fileObj
                })
                machineContentRowPayload = {
                    name: name,
                    content_type_id: machineContentRow.content_type.id
                }
            } else {
                machineContentRowPayload = {
                    name: name,
                    content_type_id: machineContentRow.content_type.id,
                    content: contentRowText,
                }
            }
            await updateMachineContentRow({
                machineId: machine.id,
                machineContentId: machineContent.id,
                machineContentRowId: machineContentRow.id,
                payload: machineContentRowPayload
            })

            setExpandedRow(undefined)
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSaving(false)
    }

    return (
        <Box
            component={Paper}
            sx={{
                backgroundColor: "white",
                width: "50rem",
                pt: 2,
                px: 4,
                pb: 3,
            }}
        >
            <Box
                component={"form"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    gap: "1rem"
                }}
                onSubmit={handleUpdateMachineContentRow}
            >
                <TextField
                    fullWidth={true}
                    required={true}
                    label="Titolo contenuto"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    inputProps={{
                        maxLength: machineContentRowFormCharLimits.name,
                    }}
                />
                {machineContentRow.content_type.type === "pdf"
                    ? <Stack
                        gap={1}
                        justifyContent={"space-around"}
                        sx={{
                            overflowWrap: "break-word",
                            wordBreak: "break-all"
                        }}
                    >
                        <input
                            style={{display: 'none'}}
                            id="button-dropdown-pdf"
                            accept={"application/pdf"}
                            type="file"
                            onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                        />
                        <Stack
                            direction={"row"}
                            component={"label"}
                            htmlFor="button-dropdown-pdf"
                            alignItems={"end"}
                            sx={{width: "100%", borderBottom: "1px solid gray", pb: 1}}
                            gap={1}
                        >
                            <Button
                                variant="contained"
                                component="span"
                                disableElevation={true}
                                size={"small"}
                            >
                                Carica PDF
                            </Button>
                            <Typography
                                color={"gray"}
                                sx={{maxHeight: "2rem", overflowY: "scroll"}}
                            >
                                {contentRowFileName ? contentRowFileName : "Selezione un documento PDF"}
                            </Typography>
                        </Stack>
                    </Stack>
                    : machineContentRow.content_type.type === "img"
                        ? <Stack
                            gap={1}
                            justifyContent={"space-around"}
                            sx={{
                                overflowWrap: "break-word",
                                wordBreak: "break-all"
                            }}
                        >
                            <input
                                style={{display: 'none'}}
                                id="button-dropdown-img"
                                accept={"image/png, image/gif, image/jpeg"}
                                type="file"
                                onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                            />
                            <Stack
                                direction={"row"}
                                component={"label"}
                                htmlFor="button-dropdown-img"
                                alignItems={"end"}
                                sx={{width: "100%", borderBottom: "1px solid gray", pb: 1}}
                                gap={1}
                            >
                                <WindowTooltip
                                    title={
                                        <Box sx={{maxWidth: "30rem", maxHeight: "30rem"}}>
                                            <Typography>Immagine caricata:</Typography>
                                            <Box
                                                sx={{maxWidth: "20rem", maxHeight: "20rem", borderRadius: "5px", m: 2}}
                                                component={"img"}
                                                src={`${ENDPOINT as string + "/" + machineContentRow.content}`}
                                                alt={"Nessuna immagine caricata"}
                                            />
                                        </Box>
                                    }
                                    placement={"top"}
                                >
                                    <IconButton component="span">
                                        {machineContentRow.content ? <RiImageAddFill/> : <RiImageAddLine/>}
                                    </IconButton>
                                </WindowTooltip>
                                <Typography
                                    color={"gray"}
                                    sx={{maxHeight: "2rem", overflowY: "scroll"}}
                                >
                                    {contentRowFileName ? contentRowFileName : "Immagine macchinario "}
                                </Typography>
                            </Stack>
                        </Stack>
                        : machineContentRow.content_type.type === "yt" || machineContentRow.content_type.type === "link"
                            ? <TextField
                                type={"url"}
                                fullWidth={true}
                                label={
                                    (machineContentRow.content_type.type === "yt" && "Link di youtube") ||
                                    (machineContentRow.content_type.type === "link" && "Link") ||
                                    ""
                                }
                                required={true}
                                value={contentRowText}
                                onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                                variant="standard"
                                inputProps={{maxLength: machineContentRowFormCharLimits.content}}
                            />
                            : <TextField
                                type={"text"}
                                fullWidth={true}
                                label={"Descrizione"}
                                required={true}
                                value={contentRowText}
                                multiline
                                minRows={2}
                                maxRows={10}
                                onChange={(event) => handleContentChange(event as ChangeEvent<HTMLInputElement>)}
                                variant="standard"
                                inputProps={{maxLength: machineContentRowFormCharLimits.content}}
                            />
                }
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        disabled={isSaving || (
                            machineContentRow.name === name &&
                            machineContentRow.content === contentRowText &&
                            !contentRowFile
                        )}
                    >
                        Salva
                    </Button>
                </Box>
                {isSaving &&
                    <LinearProgress/>
                }
            </Box>
        </Box>
    )
}

export default ContentPairerRowFieldDropdown;
