import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../logics/api_config";

async function doUpdate(machineId: number, payload: any): Promise<void> {
    try {
        await API.putEncoded(`/backoffice/machine/${machineId}`, payload);
    } catch (error) {
        throw error;
    }
}

const useApiUpdateMachine = (): UseMutationResult<void, CustomAxiosError, { machineId: number; payload: any }> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, { machineId: number; payload: any }>(
        ({machineId, payload}) => doUpdate(machineId, payload),
        {
            onSuccess: (data,variables) => {
                if(variables){
                    const machineId = variables.machineId
                    queryClient.invalidateQueries(["getMachines"])
                    queryClient.invalidateQueries(["getMachineDetailed",machineId])
                }

            }
        }
    );
};

export default useApiUpdateMachine;