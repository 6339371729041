import {useQuery, UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../../../logics/api_config";
import {IRegistryPlantMachine} from "../../../../../utils/interfaces/Registry/RegistryPlant/IRegistryPlantMachine";

async function doGet(registryId: number, registryPlantId: number): Promise<IRegistryPlantMachine[] | undefined> {
    try {
        const response = await API.get(`/backoffice/registry/${registryId}/plant/${registryPlantId}/registry_plant_machine`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

const useApiGetRegistryPlantMachines = (registryId: number, registryPlantId: number): UseQueryResult<IRegistryPlantMachine[] | undefined, CustomAxiosError> => {
    return useQuery<IRegistryPlantMachine[] | undefined, CustomAxiosError>(
        ["getRegistryPlantMachines", registryPlantId],
        () => doGet(registryId, registryPlantId),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export default useApiGetRegistryPlantMachines;
