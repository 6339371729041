import React, {useMemo} from "react";
import {Box, Button, MenuItem, Stack, Typography} from "@mui/material";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {RiEditFill} from "react-icons/ri";
import {MRT_Localization_IT} from "material-react-table/locales/it";
import ContentManagerTableEditorModal from "./ContentManagerTableComponents/ContentManagerTableEditorModal";
import {IMachineContent} from "../../../../../utils/interfaces/Machine/MachineContent/IMachineContent";
import {categoryNamesIta} from "../../../../../utils/interfaces/ICategory";
import ContentManagerTableModalConfirmDelete
    from "./ContentManagerTableComponents/ContentManagerTableModalConfirmDelete";
import {FaTrash} from "react-icons/fa";
import ButtonOpenModal from "../../../../../ui/OpenModal/ButtonOpenModal";

interface Props {
    machine: {
        id: number,
    },
    machineContent: IMachineContent[]
}

const ContentRowManagerTable = ({machine, machineContent}: Props) => {
    const machineContents: IMachineContent[] = machineContent


    const machineContentRowColumns = useMemo<MRT_ColumnDef<IMachineContent>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Nome riga",
            },
            {
                accessorKey: "category.id",
                header: "Categoria",
                Cell: ({ cell }) => {
                    const renderedCategoryName = categoryNamesIta[cell.getValue<number>()-1]
                    return <Box>{renderedCategoryName}</Box>;
                },
            },
        ],
        [],
    );

    const tableConfig = useMaterialReactTable({
        columns: machineContentRowColumns,
        data: machineContents,
        initialState: {density: 'compact'},
        positionCreatingRow: 'bottom',
        positionActionsColumn: 'last',
        enableEditing: true,
        enableTableHead: false,
        enableTopToolbar: false,
        enablePagination: false,
        muiTableContainerProps: {
            sx: {
                height: "17rem",
                maxHeight: "17rem",
                width: "39rem",
            },
        },
        muiTablePaperProps: {
            sx: {
                border: "1px solid #dddddd",
                borderRadius: "0px",
            },
            elevation: 0,
        },
        muiTableBodyCellProps: {
            sx: {
                width: "4rem",
                maxWidth: "4rem",
                overflow: "auto",
                borderBottom: `1px solid #dddddd`,
                borderRight: `1px solid #dddddd`,
                py: 0.2,
            },
        },
        muiCreateRowModalProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps: {
                sx: {
                    boxShadow: "none",
                    backgroundColor: "transparent"
                },
            },
        },
        muiEditRowDialogProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps: {
                sx: {
                    boxShadow: "none",
                    backgroundColor: "transparent"
                },
            },
        },
        renderBottomToolbar: ({table}) => (
            <div>
                <Button
                    variant="contained"
                    fullWidth={true}
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    Nuovo argomento
                </Button>
            </div>
        ),
        //Row creation modal
        renderCreateRowDialogContent: ({table}) => (
            <ContentManagerTableEditorModal
                machine={{id: machine.id}}
                setIsModalClosed={() => table.setCreatingRow(null)}
            />
        ),
        //Row editing modal
        renderEditRowDialogContent: ({row, table}) => (
            <ContentManagerTableEditorModal
                machine={{id: machine.id}}
                id={row.original.id}
                setIsModalClosed={() => table.setEditingRow(null)}
            />
        ),
        // When there is no content
        renderEmptyRowsFallback: () => (
            <Typography
                sx={{
                    padding: 3,
                    color: "#99A1AA",
                    display: "flex",
                    justifyContent: "center",
                    fontStyle: "italic"
                }}
            >
                Questo macchinario non ha ancora contenuti. Aggiungine adesso!
            </Typography>
        ),
        //Delete button in action menu
        renderRowActionMenuItems: ({row}) => [
            <ButtonOpenModal
                key={0}
                ids={{machineId: machine.id, machineContentId: row.original.id}}
                style={{color: "#c4402f", textTransform: "none", fontWeight: "bold"}}
                Component={ContentManagerTableModalConfirmDelete}
            >
                <MenuItem sx={{gap: "10px"}}>
                    <FaTrash/>
                    <Typography sx={{fontSize: "0.9rem"}}>Elimina Argomento</Typography>
                </MenuItem>
            </ButtonOpenModal>
        ],
        icons: {EditIcon: () => <RiEditFill/>},
        localization: MRT_Localization_IT
    })

    return (
        <Stack>
            <Typography>
                Argomenti
            </Typography>
            <MaterialReactTable table={tableConfig}/>
        </Stack>
    )
}

export default ContentRowManagerTable
