import {Box, Button, LinearProgress, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import useApiCreateRegistryPlant from "../../../api-hooks/RegistryPlant/useApiCreateRegistryPlant";
import useApiUpdateRegistryPlant from "../../../api-hooks/RegistryPlant/useApiUpdateRegistryPlant";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {plantFormCharLimits} from "../../../../../../utils/content/content-formLimits";

interface Props {
    registry: {
        id: number,
        address: string,
        city: string,
        cap: string
        nation: string,
    }
    plant?: {
        id?: number,
        name?: string,
        address?: string,
        city?: string,
        cap?: string,
        nation?: string,
    }
    onPostIdChange: (id: number) => void;
}

const PlantManagerTableEditingForm = ({registry, plant, onPostIdChange}: Props) => {
    const [name, setName] = useState<string | undefined>(plant?.name);
    const [address, setAddress] = useState<string | undefined>(plant?.address ? plant.address : registry.address);
    const [city, setCity] = useState<string | undefined>(plant?.city ? plant.city : registry.city);
    const [cap, setCap] = useState<string | undefined>(plant?.cap ? plant.cap : registry.cap);
    const [nation, setNation] = useState<string | undefined>(registry?.nation);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const createMutation = useApiCreateRegistryPlant()
    const updateMutation = useApiUpdateRegistryPlant();

    const handleChangeBusinessName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    }

    const handleChangeCity = (event: ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    }

    const handleChangeCap = (event: ChangeEvent<HTMLInputElement>) => {
        setCap(event.target.value);
    }

    const handleChangeNation = (event: ChangeEvent<HTMLInputElement>) => {
        setNation(event.target.value);
    }

    const handleCreateRegistryPlant = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        const payload = {
            name: name || "",
            address: address || "",
            city: city || "",
            cap: cap || "",
            nation: nation || "",
            nickname: name ||  "",
        };
        try {
            const result = await createMutation.mutateAsync({registryId: registry.id, payload: payload})
            onPostIdChange(result.id)
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    const handleConfirmEdit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true);
        const payload = {
            name: name || "",
            address: address || "",
            city: city || "",
            cap: cap || "",
            nation_: nation || "",
            nickname: name ||  "",
        };
        try {
            if (plant?.id) {
                await updateMutation.mutateAsync({
                    registryId: registry.id,
                    registryPlantId: plant.id,
                    payload: payload
                });
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        setName(plant?.name);
        setAddress(plant?.address ? plant.address : registry.address);
        setCity(plant?.city ? plant.city : registry.city);
        setCap(plant?.cap ? plant.cap : registry.cap);
    }, [plant]);

    return (
        <div>
            <Typography>
                Impianto
            </Typography>
            <Box
                component={"form"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem"
                }}
                onSubmit={(event) => {
                    plant ? handleConfirmEdit(event) : handleCreateRegistryPlant(event)
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "0.5rem"
                    }}
                >
                    <TextField
                        required={true}
                        label={"Nome impianto"}
                        name={"Nome impianto"}
                        value={name || ""}
                        onChange={handleChangeBusinessName}
                        disabled={isSaving}
                        inputProps={{maxLength: plantFormCharLimits.name}}
                    />
                    <TextField
                        label={"Indirizzo"}
                        name={"Indirizzo"}
                        value={address || ""}
                        onChange={handleChangeAddress}
                        disabled={isSaving}
                        inputProps={{maxLength: plantFormCharLimits.address}}
                    />
                    <TextField
                        label={"Città"}
                        name={"Città"}
                        value={city || ""}
                        onChange={handleChangeCity}
                        disabled={isSaving}
                        inputProps={{maxLength: plantFormCharLimits.city}}
                    />
                    <TextField
                        sx={{
                            width: "100px"
                        }}
                        label={"Cap"}
                        name={"Cap"}
                        value={cap || ""}
                        onChange={handleChangeCap}
                        disabled={isSaving}
                        inputProps={{maxLength: plantFormCharLimits.cap}}
                    />
                    <TextField
                        label={"Nazione"}
                        name={"Nazione"}
                        value={nation || ""}
                        onChange={handleChangeNation}
                        disabled={isSaving}
                        inputProps={{maxLength: plantFormCharLimits.nation}}
                    />
                </Box>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        disabled={isSaving || (
                            plant?.name === name &&
                            plant?.city === city &&
                            plant?.address === address &&
                            plant?.cap === cap
                        )}
                    >
                        {plant
                            ? "Salva"
                            : "Crea impianto"
                        }
                    </Button>
                </Box>
                {isSaving &&
                    <LinearProgress/>
                }
            </Box>
        </div>
    );
}

export default PlantManagerTableEditingForm;