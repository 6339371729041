import {Box, Collapse, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import ModalCloseButton from "../../../../../../ui/ModalCloseButton";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import ContentManagerTableEditingForm from "./ContentManagerTableEditingForm";
import useApiGetMachineContentDetailed from "../../../api-hooks/MachineContent/useApiGetMachineContentDetailed";
import {
    IMachineContentDetailed
} from "../../../../../../utils/interfaces/Machine/MachineContent/IMachineContentDetailed";
import ContentPairerRowFields from "./ContentManagerTablePairerComponents/ContentPairerRowFields";

interface Props {
    machine: {
        id: number,
    },
    id?: number | undefined,
    setIsModalClosed: () => void
}

const ContentManagerTableEditorModal = ({machine, id, setIsModalClosed}: Props) => {
    const [machineContent, setMachineContent] = useState<IMachineContentDetailed>()
    const [postId, setPostId] = useState<number>();

    const handleIdChange = (id: number) => {
        setPostId(id);
    };

    const {
        data: fetchedMachineContent,
        isFetching: fetchedMachineContentLoading,
        error: fetchedMachineContentError
    } = useApiGetMachineContentDetailed(
        machine.id,
        postId ? postId : id
    )
    if (fetchedMachineContentError) {
        const axiosError = fetchedMachineContentError as CustomAxiosError;
        console.log(axiosError);
    }

    useEffect(() => {
        if (fetchedMachineContent) {
            setMachineContent(fetchedMachineContent)
        }
    }, [fetchedMachineContent]);


    return (
        <>
            <ModalCloseButton setIsModalClosed={setIsModalClosed}/>
            <Box
                sx={{
                    backgroundColor: "white",
                    boxShadow: 2,
                    border: '3px solid',
                    borderColor: "primary.dark",
                    borderRadius: "4px",
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}
            >
                <Stack direction={"column"}>
                    <ContentManagerTableEditingForm
                        machine={machine}
                        machineContent={machineContent}
                        onPostIdChange={handleIdChange}
                    />
                    <Collapse in={!!machineContent}>
                        {machineContent &&
                            <ContentPairerRowFields
                                machine={machine}
                                machineContent={machineContent}
                                isFetching={fetchedMachineContentLoading}
                            />
                        }
                    </Collapse>
                </Stack>
            </Box>
        </>
    )
}

export default ContentManagerTableEditorModal;
