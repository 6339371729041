import {useQuery,UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from "../../../logics/api_config";
import {IMachine} from "../../../utils/interfaces/Machine/IMachine";

async function doGet(): Promise<IMachine[] | undefined> {
    try {
        const response = await API.get("/backoffice/machine");
        return response.data.data;
    } catch (error) {
        const ax_error = error as CustomAxiosError
        throw error;
    }
}

const useApiGetMachines = (): UseQueryResult<IMachine[] | undefined, CustomAxiosError> => {
    return useQuery<IMachine[] | undefined, CustomAxiosError>(
        ["getMachines"],
        () => doGet(),
    );
};

export default useApiGetMachines;
