import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {API, CustomAxiosError} from "../../../../../logics/api_config";

async function doUpdate(registryId: number, registryPlantId: number, registryPlantMachineId: number, payload: any): Promise<void> {
    try {
        await API.putEncoded(`/backoffice/registry/${registryId}/plant/${registryPlantId}/registry_plant_machine/${registryPlantMachineId}`, payload);
    } catch (error) {
        throw error;
    }
}

const useApiUpdateRegistryPlantMachine = (): UseMutationResult<void, CustomAxiosError, {
    registryId: number;
    registryPlantId: number;
    registryPlantMachineId: number;
    payload: any
}> => {
    const queryClient = useQueryClient();
    return useMutation<void, CustomAxiosError, {
        registryId: number;
        registryPlantId: number;
        registryPlantMachineId: number;
        payload: any
    }>(
        ({
             registryId,
             registryPlantId,
             registryPlantMachineId,
             payload
         }) => doUpdate(registryId, registryPlantId, registryPlantMachineId, payload),
        {
            onSuccess: (data, variables) => {
                const {registryPlantId} = variables;
                if (registryPlantId) {
                    queryClient.invalidateQueries(["getRegistryPlantMachines", registryPlantId]);
                }
            }
        }
    );
};

export default useApiUpdateRegistryPlantMachine;