import React, {useState} from 'react';
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
} from "@mui/material";
import {contentNavbarEntries} from "../utils/content/content-navbar";
import {useNavigate,} from "react-router-dom";
import {IconContext} from "react-icons";
import {DrawerHeader} from "./DrawerHeader";
import {MdArrowBackIos} from "react-icons/md";
import ProjectVersion from "./ProjectVersion";
import {RiLoginBoxLine} from "react-icons/ri";
import ButtonLogout from "./ButtonLogout";

interface Props {
    isOpen?: boolean,
    handleToggle?: () => void,
    setPageTitle: (title:string) => void,
}

const NavBar = ({isOpen = false, handleToggle, setPageTitle}: Props) => {
    const navigate = useNavigate();

    //Default highlighted page is set here manually
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const handleNavbarNavigation = (to: string, i: number) => {
        setSelectedIndex(i);
        if (to[0] !== "/") {
            window.location.href = to;
        } else {
            navigate(to)
        }
    }

    return (
        <Drawer
            variant={"persistent"}
            open={isOpen}
        >
            <IconContext.Provider
                value={{color: "white", size: "20px"}}
            >
                <DrawerHeader>
                    <IconButton
                        onClick={handleToggle}
                        sx={{
                            transition: 'transform 0.2s ease',
                            transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
                            paddingRight: "4px",
                        }}
                    >
                        <MdArrowBackIos/>
                    </IconButton>
                </DrawerHeader>
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRight:"3px solid",
                        borderColor:"primary.dark",
                }}>
                    <Stack justifyContent="space-between">
                        <List>
                            {contentNavbarEntries.map((entryObj, index) => (
                                <React.Fragment key={index}>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={() => {
                                                handleNavbarNavigation(entryObj.to, index);
                                                setPageTitle(entryObj.name)
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    borderColor: "primary.dark",
                                                    borderLeftWidth: selectedIndex === index ? 4 : 0,
                                                }}
                                            >
                                                {entryObj.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={entryObj.name}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </Stack>
                    <Stack sx={{display:"flex", justifyContent:"center"}} gap={2}>
                        <ButtonLogout
                            logoutIcon={<RiLoginBoxLine/>}
                            flipLogoutIcon={true}
                            disableElevation={true}
                        />
                        <ProjectVersion/>
                    </Stack>
                </Box>
            </IconContext.Provider>
        </Drawer>
    );
};

export default NavBar;