import React, { useContext, useState } from "react";

type ProviderProps = {
    children: React.ReactNode,
}

type AuthContextType = {
    isAuthenticated: boolean,
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
}

const defaultAuthContextValue: AuthContextType = {
    isAuthenticated: false,
    setIsAuthenticated: () => {},
}

const authContext = React.createContext<AuthContextType>(defaultAuthContextValue);

function useAuth() {
    const context = useContext(authContext);
    if (!context) {
        throw new Error('useAuth must be used within an authProvider');
    }
    return context;
}

function useProvideAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    return {
        isAuthenticated, setIsAuthenticated,
    }
}

function ProvideAuth({children}: ProviderProps) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

export {
    ProvideAuth,
    useAuth
}
