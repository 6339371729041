import {useQuery, UseQueryResult} from 'react-query';
import {API, CustomAxiosError} from '../../../../../logics/api_config';
import {IMachineContentDetailed} from "../../../../../utils/interfaces/Machine/MachineContent/IMachineContentDetailed";

async function doGet(
    machineId: number,
    machineContentId: number | undefined
): Promise<IMachineContentDetailed | undefined> {
    try {
        const response = await API.get(`/backoffice/machine/${machineId}/machine_content/${machineContentId}`);
        return response.data.data[0];
    } catch (error) {
        const ax_error = error as CustomAxiosError;
        throw ax_error.response?.data || ax_error.message;
    }
}

const useApiGetMachineContentDetailed = (
    machineId: number,
    machineContentId: number | undefined
): UseQueryResult<IMachineContentDetailed | undefined, CustomAxiosError> => {
    return useQuery<IMachineContentDetailed | undefined, CustomAxiosError>(
        ['getMachineContentDetailed', machineContentId],
        () => doGet(machineId, machineContentId),
        {
            enabled: !!machineContentId,
            refetchOnWindowFocus: false,
        }
    );
};

export default useApiGetMachineContentDetailed;
