import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {API, CustomAxiosError} from "../../../logics/api_config";

async function doPostLogout(): Promise<void> {
    try {
        const response = await API.post('/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
}

const useDoPostLogout = (): UseMutationResult<void, CustomAxiosError, void> => {
    const queryClient = useQueryClient();

    return useMutation<void, CustomAxiosError, void>({
        mutationFn: doPostLogout,
        onSuccess: () => {
            queryClient.invalidateQueries(["whoAmI"]);
        },
    });
};

export default useDoPostLogout;
