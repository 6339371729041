import React, {FormEvent, useEffect, useState} from "react";
import {Box, Button, LinearProgress, Paper, TextField} from "@mui/material";
import {
    machineContentRowFormCharLimits,
    machineFormCharLimits
} from "../../../../../../../utils/content/content-formLimits";
import useApiUpdateRegistryPlantMachine
    from "../../../../api-hooks/RegistryPlantMachine/useApiUpdateRegistryPlantMachine";
import useApiGetRegistryPlantMachineDetailed
    from "../../../../api-hooks/RegistryPlantMachine/useApiGetRegistryPlantMachineDetailed";

interface Props {
    registry: {
        id: number
    }
    registryPlant: {
        id: number
    }
    registryPlantMachine: {
        id: number
    }
    setExpandedRow: (expandedRow: number | undefined) => void
}

const PlantPairerMachineFieldDropdown = ({registry, registryPlant, registryPlantMachine, setExpandedRow}: Props) => {
    const [serialNumber, setSerialNumber] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const {mutateAsync: updateMachineContentRow} = useApiUpdateRegistryPlantMachine()
    const {
        data: fetchedRegistryPlantMachineDetailed,
        error: fetchedRegistryPlantMachineDetailedError,
        isFetching: fetchedRegistryPlantMachineDetailedLoading
    } = useApiGetRegistryPlantMachineDetailed(registry.id, registryPlant.id, registryPlantMachine.id)
    if (fetchedRegistryPlantMachineDetailedError) {
        console.log(fetchedRegistryPlantMachineDetailedError)
    }

    const handleUpdateMachineContentRow = async (e: FormEvent<HTMLFormElement>) => {
        setIsSaving(true)
        e.preventDefault()

        try {
            const payload = {
                description: description,
                serial_number: serialNumber,
            }
            await updateMachineContentRow({
                registryId: registry.id,
                registryPlantId: registryPlant.id,
                registryPlantMachineId: registryPlantMachine.id,
                payload: payload
            })
            setExpandedRow(undefined)
        } catch (error) {
            console.log(error)
        }

        setIsSaving(false)
    }

    useEffect(() => {
        if (fetchedRegistryPlantMachineDetailed) {
            setDescription(fetchedRegistryPlantMachineDetailed.description)
            setSerialNumber(fetchedRegistryPlantMachineDetailed.serial_number)
        }
    }, [fetchedRegistryPlantMachineDetailed]);

    return (
        <Box
            component={Paper}
            sx={{
                backgroundColor: "white",
                width: "100%",
                pt: 2,
                px: 4,
                pb: 3,
            }}
        >
            {fetchedRegistryPlantMachineDetailedLoading
                ? <LinearProgress/>
                : <Box
                    component={"form"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        gap: "1rem"
                    }}
                    onSubmit={handleUpdateMachineContentRow}
                >
                    <TextField
                        fullWidth={true}
                        required={true}
                        label="N° matricolazione"
                        value={serialNumber}
                        onChange={(event) => setSerialNumber(event.target.value)}
                        inputProps={{
                            maxLength: machineContentRowFormCharLimits.name,
                        }}
                    />
                    <TextField
                        fullWidth={true}
                        required={true}
                        label={"Descrizione"}
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        multiline
                        minRows={2}
                        maxRows={10}
                        variant="standard"
                        inputProps={{maxLength: machineFormCharLimits.description}}
                    />
                    <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            type={"submit"}
                            variant={"contained"}
                            disabled={isSaving || (
                                fetchedRegistryPlantMachineDetailed?.serial_number === serialNumber &&
                                fetchedRegistryPlantMachineDetailed?.description === description
                            )}
                        >
                            Salva
                        </Button>
                    </Box>
                    {isSaving &&
                        <LinearProgress/>
                    }
                </Box>
            }
        </Box>
    )
}

export default PlantPairerMachineFieldDropdown;
