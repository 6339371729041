import PlantPairerMachineInput from "./PlantPairerMachineInput";
import {
    Box,
    IconButton,
    LinearProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import useApiDeleteRegistryPlantMachine
    from "../../../../api-hooks/RegistryPlantMachine/useApiDeleteRegistryPlantMachine";
import useApiGetMachines from "../../../../../../Machines/api-hooks/useApiGetMachines";
import useApiGetRegistryPlantMachines from "../../../../api-hooks/RegistryPlantMachine/useApiGetRegistryPlantMachines";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import useApiUpdateRegistryPlantMachine
    from "../../../../api-hooks/RegistryPlantMachine/useApiUpdateRegistryPlantMachine";
import {BiCollapseAlt} from "react-icons/bi";
import {RiEditFill} from "react-icons/ri";
import PlantPairerMachineFieldDropdown from "./PlantPairerMachineFieldDropdown";

interface Props {
    registry: {
        id: number,
    }
    plant: {
        id: number
    }
}

const PlantPairerMachineFields = ({registry, plant}: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [expandedRow, setExpandedRow] = useState<number | undefined>(undefined)

    const {mutateAsync: deleteRegistryPlantMachine} = useApiDeleteRegistryPlantMachine()
    const {mutateAsync: updateRegistryPlantMachine} = useApiUpdateRegistryPlantMachine()

    const {data: fetchedMachines, error: fetchedMachinesError} = useApiGetMachines()
    if (fetchedMachinesError) {
        const axiosError = fetchedMachinesError as CustomAxiosError;
        console.log(axiosError);
    }

    const handleExpandRow = (rowIndex: number) => {
        setExpandedRow(rowIndex !== expandedRow ? rowIndex : undefined)
    }

    const {
        data: fetchedRegistryPlantMachines,
        error: registryPlantMachinesError
    } = useApiGetRegistryPlantMachines(registry.id, plant.id)
    if (registryPlantMachinesError) {
        const axiosError = registryPlantMachinesError as CustomAxiosError;
        console.log(axiosError);
    }

    const handleDeleteRegistryPlantMachine = async (registryPlantMachineId: number) => {
        setIsSubmitting(true)
        try {
            await deleteRegistryPlantMachine({
                registryId: registry.id,
                registryPlantId: plant.id,
                registryPlantMachineId: registryPlantMachineId
            })
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    const handleUpdateRegistryPlantMachineRowPosition = async (registryPlantMachineId: number, registryPlantMachineNewRowPosition: number) => {
        setIsSubmitting(true)
        try {
            await updateRegistryPlantMachine({
                registryId: registry.id,
                registryPlantId: plant.id,
                registryPlantMachineId: registryPlantMachineId,
                payload: {row_position: registryPlantMachineNewRowPosition}
            })
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.message) {
                    const detailError = axiosError.response.data.message;
                    console.log(detailError);
                }
            }
        }
        setIsSubmitting(false)
    }

    return (
        <Stack sx={{height: "100%", borderLeft: "1px solid #DFDFDFFF"}} gap={1}>
            {fetchedMachines && fetchedRegistryPlantMachines
                ? <PlantPairerMachineInput
                    registry={registry}
                    registryPlantId={plant.id}
                    machines={fetchedMachines}
                    setIsSubmitting={setIsSubmitting}
                />
                : ""
            }
            <div style={{width: "100%", height: '28rem', overflow: 'auto',}}>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {fetchedRegistryPlantMachines?.map((registryPlantMachine, index) => (
                                <TableRow key={registryPlantMachine.id} sx={{
                                    "&:hover": {
                                        backgroundColor: "#dee2e2"
                                    }
                                }}>
                                    <TableCell sx={{width: "38rem", borderRight: "1px solid #b7bcc3"}}>
                                        <Stack>
                                            <Typography>{registryPlantMachine.machine.name}</Typography>
                                            {expandedRow === index
                                                ? ""
                                                : <Typography
                                                    sx={{
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        color: "gray",
                                                    }}>
                                                    {registryPlantMachine.serial_number}
                                                </Typography>
                                            }
                                            {expandedRow === index
                                                ? <PlantPairerMachineFieldDropdown
                                                    registry={registry}
                                                    registryPlant={plant}
                                                    registryPlantMachine={{id: registryPlantMachine.id}}
                                                    setExpandedRow={setExpandedRow}
                                                />
                                                : ""

                                            }
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack>
                                            <IconButton
                                                sx={{p: 0, m: 0}}
                                                size={"small"}
                                                disabled={registryPlantMachine.row_position === 1}
                                                onClick={
                                                    () => {
                                                        handleUpdateRegistryPlantMachineRowPosition(
                                                            registryPlantMachine.id,
                                                            (registryPlantMachine.row_position - 1)
                                                        )
                                                    }
                                                }
                                            >
                                                <ArrowUpward fontSize={"small"}/>
                                            </IconButton>
                                            <IconButton
                                                sx={{p: 0, m: 0}}
                                                size={"small"}
                                                disabled={index === fetchedRegistryPlantMachines.length - 1}
                                                onClick={
                                                    () => {
                                                        handleUpdateRegistryPlantMachineRowPosition(
                                                            registryPlantMachine.id,
                                                            ++registryPlantMachine.row_position
                                                        )
                                                    }
                                                }
                                            >
                                                <ArrowDownward fontSize={"small"}/>
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => handleExpandRow(index)}
                                        >
                                            {index === expandedRow
                                                ? <BiCollapseAlt/>
                                                : <RiEditFill/>
                                            }

                                        </IconButton>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
                                            <IconButton
                                                size={"small"}
                                                onClick={
                                                    () => handleDeleteRegistryPlantMachine(registryPlantMachine.id)
                                                }
                                            >
                                                X
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {isSubmitting &&
                <LinearProgress/>
            }
        </Stack>
    )
}

export default PlantPairerMachineFields
