import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, Typography} from "@mui/material";
import {CustomAxiosError} from "../../logics/api_config";
import {RiEditFill} from "react-icons/ri";
import {MaterialReactTable, MRT_ColumnDef, MRT_TablePagination, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_IT} from "material-react-table/locales/it";
import {IMachine} from "../../utils/interfaces/Machine/IMachine";
import useApiGetMachines from "./api-hooks/useApiGetMachines";
import MachineEditorModal from "./MachinesComponents/MachineEditorModal";

const MachinesPage = () => {
    const [machines, setMachines] = useState<IMachine[]>([])
    const {data: fetchedMachines, isFetching: fetchedMachinesLoading, error: fetchedMachinesError} = useApiGetMachines()
    if (fetchedMachinesError) {
        const axiosError = fetchedMachinesError as CustomAxiosError;
        console.log(axiosError);
    }

    useEffect(() => {
        if (fetchedMachines) {
            setMachines(fetchedMachines);
        }
    }, [fetchedMachines]);

    const machinesColumns = useMemo<MRT_ColumnDef<IMachine>[]>(
        () => [
            // {
            //     accessorKey: "id",
            //     header: "ID",
            // },
            {
                accessorKey: "name",
                header: "Nome",
            },
        ],
        [],
    );

    const tableConfig = useMaterialReactTable({
        columns: machinesColumns,
        data: machines,
        state: {
            showProgressBars: fetchedMachinesLoading,
        },
        initialState: {
            pagination: {pageSize: 20, pageIndex: 0},
        },
        paginationDisplayMode: 'pages',
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableColumnActions: false,
        enableHiding: false,
        enableEditing: true,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            "mrt-row-actions": {
                header: "",
                size: 0,
            },
        },
        muiPaginationProps: {
            color: "primary",
            shape: "rounded",
            showRowsPerPage: false,
            variant: "text",
        },
        muiTablePaperProps: {
            sx: {
                border: "1px solid #dddddd",
                borderRadius: "0px",
                borderBottomRightRadius: "8px",
                // maxHeight:"95vh",
            },
            elevation: 0,
        },
        muiTableBodyCellProps: {
            sx: {
                borderBottom: `1px solid #dddddd`,
                borderRight:`1px solid #dddddd`,
                maxWidth: "5rem",
                py:0.2,
            },
        },
        muiCreateRowModalProps:{
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps:{
                sx:{
                    boxShadow:"none",
                    backgroundColor:"transparent"
                },
            },
        },
        muiEditRowDialogProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps:{
                sx:{
                    boxShadow:"none",
                    backgroundColor:"transparent"
                },
            },
        },
        //Row creation and editing modal
        renderEditRowDialogContent: ({row,table}) => (
            <MachineEditorModal
                id={row.original.id}
                setIsModalClosed={() => {
                    table.setEditingRow(null);
                    table.setCreatingRow(null)
                }}
            />
        ),
        renderBottomToolbar: ({table}) => (
            <Box sx={{display: "flex", alignContent: "center", justifyContent: "end"}}>
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_TablePagination table={table}/>
                <Button
                    sx={{
                        width: "10%",
                        borderRadius: "0px",
                    }}
                    variant="contained"
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    Aggiungi macchinario
                </Button>
            </Box>
        ),
        // When there is no content
        renderEmptyRowsFallback: () => (
            <>
                {!fetchedMachinesLoading &&
                    <Typography
                        sx={{
                            padding: 3,
                            color: "#99A1AA",
                            display: "flex",
                            justifyContent: "center",
                            fontStyle: "italic"
                        }}
                    >
                        Non sono ancora stati creati macchinari. Creane uno adesso!
                    </Typography>
                }
            </>
        ),
        // TODO Handle errors when the object cannot be deleted
        // renderRowActionMenuItems: ({ closeMenu, row }) => [
        //     <ButtonOpenModal
        //         key={0}
        //         ids={{registryId:row.original.id}}
        //         style={{ color: "#c4402f", textTransform: "none", fontWeight: "bold"}}
        //         Component={RegistryModalConfirmDelete}
        //     >
        //         <MenuItem sx={{gap:"10px"}}>
        //             <FaTrash/>
        //             <Typography sx={{fontSize:"0.9rem"}}>Elimina azienda</Typography>
        //         </MenuItem>
        //     </ButtonOpenModal>
        // ],
        icons: {EditIcon: () => <RiEditFill/>},
        localization: MRT_Localization_IT
    })

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box sx={{width: "78%"}}>
                <MaterialReactTable
                    table={tableConfig}
                />
            </Box>
        </Box>
    );
};

export default MachinesPage;
