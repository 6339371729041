import React, {useEffect, useMemo, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import useApiGetRegistries from "./api-hooks/useApiGetRegistries";
import {CustomAxiosError} from "../../logics/api_config";
import {IRegistry} from "../../utils/interfaces/Registry/IRegistry";
import RegistryEditorModal from "./RegistryComponents/RegistryEditorModal";
import {RiEditFill} from "react-icons/ri";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    MRT_TablePagination,
    useMaterialReactTable
} from "material-react-table";
import {MRT_Localization_IT} from "material-react-table/locales/it";

const RegistryPage = () => {
    const [registries, setRegistries] = useState<IRegistry[]>([])

    const {
        data: fetchedRegistries,
        isFetching: fetchedRegistriesLoading,
        error: fetchedRegistriesError
    } = useApiGetRegistries()
    if (fetchedRegistriesError) {
        const axiosError = fetchedRegistriesError as CustomAxiosError;
        console.log(axiosError);
    }

    useEffect(() => {
        if (fetchedRegistries) {
            setRegistries(fetchedRegistries);
        }
    }, [fetchedRegistries]);

    const registryColumns = useMemo<MRT_ColumnDef<IRegistry>[]>(
        () => [
            // {
            //     accessorKey: "id",
            //     header: "ID",
            // },
            {
                accessorKey: "business_name",
                header: "Nome",
            },
        ],
        [],
    );

    const tableConfig = useMaterialReactTable({
        columns: registryColumns,
        data: registries,
        state: {
            showProgressBars: fetchedRegistriesLoading,
        },
        initialState: {
            density: "compact",
            pagination: {pageSize: 20, pageIndex: 0},
        },
        paginationDisplayMode: "pages",
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableColumnActions: false,
        enableHiding: false,
        enableEditing: true,
        positionActionsColumn: "last",
        displayColumnDefOptions: {
            "mrt-row-actions": {
                header: "",
                size: 0,
            },
        },
        muiPaginationProps: {
            color: "primary",
            shape: "rounded",
            showRowsPerPage: false,
            variant: "text",
        },
        muiTablePaperProps: {
            sx: {
                border: "1px solid #dddddd",
                borderRadius: "0px",
                borderBottomRightRadius: "8px",
                // maxHeight:"95vh",
            },
            elevation: 0,
        },
        muiTopToolbarProps: {
            sx: {
                py: "0.5rem",
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderBottom: `1px solid #dddddd`,
                borderRight: `1px solid #dddddd`,
                maxWidth: "5rem",
                py:0.2,
            },
        },
        muiCreateRowModalProps:{
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps:{
                sx:{
                    boxShadow:"none",
                    backgroundColor:"transparent"
                },
            },
        },
        muiEditRowDialogProps: {
            fullWidth: false,
            maxWidth: false,
            open: true,
            PaperProps:{
                sx:{
                    boxShadow:"none",
                    backgroundColor:"transparent"
                },
            },
        },
        //Row creation and editing modal
        renderEditRowDialogContent: ({row, table, }) => (
            <RegistryEditorModal
                id={row.original.id}
                setIsModalClosed={() => {
                    table.setEditingRow(null);
                    table.setCreatingRow(null)
                }}
            />
        ),
        renderBottomToolbar: ({table}) => (
            <Box sx={{display: "flex", alignContent: "center", justifyContent: "end"}}>
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_TablePagination table={table}/>
                <Button
                    sx={{
                        width: "10%",
                        borderRadius: "0px"
                    }}
                    variant="contained"
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    Aggiungi azienda
                </Button>
            </Box>
        ),
        // //When there is no content
        renderEmptyRowsFallback: () => (
            <>
                {!fetchedRegistriesLoading &&
                    <Typography
                        sx={{
                            padding: 3,
                            color: "#99A1AA",
                            display: "flex",
                            justifyContent: "center",
                            fontStyle: "italic"
                        }}
                    >
                        Non sono ancora state create aziende. Creane una adesso!
                    </Typography>
                }
            </>
        ),
        // TODO Handle errors when the object cannot be deleted (plants are present)
        // renderRowActionMenuItems: ({ closeMenu, row }) => [
        //     <ButtonOpenModal
        //         key={0}
        //         ids={{registryId:row.original.id}}
        //         style={{ color: "#c4402f", textTransform: "none", fontWeight: "bold"}}
        //         Component={RegistryModalConfirmDelete}
        //     >
        //         <MenuItem sx={{gap:"10px"}}>
        //             <FaTrash/>
        //             <Typography sx={{fontSize:"0.9rem"}}>Elimina azienda</Typography>
        //         </MenuItem>
        //     </ButtonOpenModal>
        // ],
        icons: {EditIcon: () => <RiEditFill/>},
        localization: MRT_Localization_IT
    })

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box sx={{width: "78%"}}>
                <MaterialReactTable
                    table={tableConfig}
                />
            </Box>
        </Box>
    );
};

export default RegistryPage;
