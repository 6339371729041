import React, {useEffect, useState} from 'react';
import {useAuth} from "../utils/contexts/auth-context";
import NavBar from "./NavBar";
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";
import {IconContext} from "react-icons";
import {RiMenuLine} from "react-icons/ri";
import useGetWhoAmI from "../utils/custom-hooks/useGetWhoAmI";

const BasePage = () => {
    const {isAuthenticated, setIsAuthenticated} = useAuth();
    const navigate = useNavigate();
    const [isNavBarOpen, setIsNavBarOpen] = useState<boolean>(true);

    //Default page title is set here manually
    const [pageTitle, setPageTitle] = useState<string>("Aziende")

    const {data: whoAmIData} = useGetWhoAmI()

    const handleOpenNavBar = () => {
        setIsNavBarOpen(true)
    }

    const handleToggleNavBar = () => {
        setIsNavBarOpen(!isNavBarOpen)
    }

    useEffect(() => {
         if (whoAmIData?.roles?.includes("ROLE_ADMIN")) {
            setIsAuthenticated(true);
            navigate('/registry');
        }
    }, [whoAmIData]);

    return (
        <>
            {isAuthenticated &&
                <>
                    <NavBar
                        isOpen={isNavBarOpen}
                        handleToggle={handleToggleNavBar}
                        setPageTitle={setPageTitle}
                    />
                    <AppBar
                        elevation={0}
                        sx={{
                            position: "sticky",
                            top: 0,
                            borderBottom: "3px solid",
                            borderColor: "primary.dark",
                        }}
                    >
                        <IconContext.Provider
                            value={{color: "white", size: "20px"}}
                        >
                            <Toolbar
                                sx={{
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <IconButton
                                    onClick={handleOpenNavBar}
                                >
                                    <RiMenuLine/>
                                </IconButton>
                                <Typography>{pageTitle}</Typography>
                            </Toolbar>
                        </IconContext.Provider>
                    </AppBar>
                    <Outlet/>
                </>
            }
        </>
    );
};

export default BasePage;
