import React, {Fragment, useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../utils/contexts/auth-context";

type Props = {
    children: React.ReactNode
}

const ProtectedRoute = ({children}: Props) => {
    const [loading, setLoading] = useState(false);
    const {isAuthenticated} = useAuth();

    useEffect(() => {
        const load = async () => {
            await setLoading(true);
            await setLoading(false);
        }
        load();
    }, [])


    return (
        <Fragment>
            {loading
                ? <></>
                : isAuthenticated
                    ? <Fragment>
                        {children}
                    </Fragment>
                    : <Navigate to="/login" replace/>
            }
        </Fragment>
    )
};

export default ProtectedRoute;
